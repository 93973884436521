import React, {useEffect} from "react";
import {Cross, Tick} from "../../components/StatusIndicators";
import {HeadingContainer} from "../../components/HeadingContainer";
import {useDispatch, useSelector} from "react-redux";
import {fetchHelpData} from "./helpSlice";
import {RootState} from "../../app/rootReducer";
import {MainLoader} from "../../components/LoadingIndicators";
import {useHistory} from "react-router";


export const Help = () => {
    const {
        error,
        isLoading,
        helpData
    } = useSelector((state: RootState) => state.help);
    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(fetchHelpData(history));
    }, [history]);

    if (isLoading) {
        return (
            <MainLoader/>
        );
    } else if (error || !helpData) {
        return <div>
            {error}
        </div>
    } else {
        return (
            <div className="container">
                <HeadingContainer>
                    <h2>Help</h2>
                </HeadingContainer>

                <p className="lead">Whilst every effort has been made to make this questionnaire as user friendly as
                    possible, we are aware
                    that there
                    may be some questions you are
                    uncertain of, some questions you may wish to change your answer to, or simply that you would like
                    some
                    help to
                    complete it.
                </p>

                <p>
                    If the following information does not answer your question, please email us or phone.
                    We are only too happy to help make this as easy as possible for you.
                </p>

                <div className="my-5">
                    <h3>What do i need to do?</h3>

                    <p>We need three things from you - a completed <strong>questionnaire</strong>, a signed <strong>Terms
                        of
                        Compilation
                        Engagement</strong> and your paperwork posted to us
                        along with a printed <strong>checklist</strong> of items you have included.
                    </p>

                    <p>To help you keep track of this, each of these items is displayed with an icon to show whether you
                        have completed the step or not. A
                        <Tick/>
                        icon indicates you have done everything you need to do for the item, while a
                        <Cross/>
                        icon indicates the item has not yet been completed.
                    </p>

                    <p>
                        Once all three items have been complete, the questionnaire will be returned to us electronically
                        and
                        move from your "Current Questionnaires"
                        list to your "All Questionnaires" list.
                    </p>
                </div>

                <div className="my-5">
                    <h3>How do i open the questionnaire?</h3>

                    <p>
                        To start working on your questionnaire(s), just click on the underlined link, "Fill in
                        Questionnaire".
                    </p>
                </div>

                <div className="my-5">
                    <h3>What do i put for my business activity?</h3>

                    <p>
                        Selecting the correct business activity/activities is very important, as this will determine the
                        questions applicable to you. So, if you have a couple of domestic rental properties and also
                        have
                        some money held on a term deposit, you would select both activities. If you are at all unsure
                        whether an activity applies to you please feel free to contact us. But don't worry if you select
                        an
                        extra activity that does not apply - this will just mean that you get some additional questions
                        that
                        you will answer 'no' to (We would much rather you select too many activities and hence
                        additional
                        information, rather than not providing information that may be needed to complete your
                        accounts/tax
                        returns).
                    </p>
                </div>

                <div className="my-5">
                    <h3>How do i navigate the questionnaire?</h3>

                    <p>Moving around the questionnaire may not be familiar, especially if you are used to moving
                        around in programs such as Microsoft's Excel or Word.
                        The ENTER key does not work in the questionnaire.</p>


                    <h4>To move between questions</h4>
                    <p>You can use your TAB key, or your mouse to move from one question to the next.</p>

                    <h4>To move between pages</h4>
                    <p>Use the 'Previous' and 'Next' buttons at the bottom of every page.</p>

                    <h4>To enter data into a table</h4>

                    <p>
                        Firstly, click on the 'Add Row' button. You will see this inserts a row into the table for you
                        to
                        complete.
                    </p>

                    <p>You can move along each row using the TAB key or your mouse.</p>

                    <p>
                        To enter a second, third, or further row click on the 'Add Row' button again after completing
                        each
                        row.
                    </p>

                    <p>
                        If you make a mistake in a row of the table, you can simply edit the rows, just like the
                        questions.
                    </p>

                    <p>
                        If you accidentally add too many rows, or add a row that needs to be deleted, that's okay too,
                        just
                        select the row and click the 'Remove' button.
                    </p>

                    <p>
                        When you have completed the table you can either TAB through, or use your mouse to select the
                        next
                        question.
                    </p>
                </div>


                <div className="my-5">
                    <h3>How do i edit questions?</h3>

                    <p>
                        All of the questions are editable by you, up until you click the 'Finalise' button at the end.
                        This
                        means that before you finalise the questionnaire (that is when it is returned to us), you can go
                        back into the questionnaire at any time and edit any of the answers you have given to any of the
                        questions.
                    </p>

                </div>

                <div className="my-5">
                    <h3>What if i don't have all the information i need right now?</h3>

                    <p>
                        As you can go back into the questionnaire any time before you finalise it, you may complete the
                        questionnaire at your convenience. If you do not have all of the information you need to
                        complete
                        the questionnaire in one sitting, you can complete the questions you do have the answers to and
                        come
                        back to it at a later time to finalise.
                    </p>

                </div>


                <div className="my-5">

                    <h3>Paper based details / computerised information</h3>

                    <p>
                        As you go through the questionnaire you will note that a number of questions
                        ask if you will be providing us with paper-based or computerised records
                        of transactions. If you answer 'No', you will be prompted to add the
                        information we need into tables.
                    </p>

                    <p>
                        So for example, if you use a computer program that records your Accounts Payable, you would
                        select
                        'Yes'
                        to the question that you will be providing paper-based details/computer records. You would also
                        select
                        'Yes' if you keep a written record of your accounts payable. The checklist generated when you
                        finalise
                        the questionnaire would ask you to provide us with either a copy of your backup file or
                        paper-based
                        (printed out from your system or hand written list) of the accounts payable. However if you
                        select
                        'No',
                        you will be prompted to enter the details of your Accounts Payable in a table.
                    </p>

                </div>

                <div className="my-5">
                    <h3>Can I add notes to the questionnaire?</h3>

                    <p>
                        Each question in the questionnaire has a <i className="fa fa-sticky-note"/> icon beside it. If
                        you click on this, you
                        can add a note to the question, which we will be able to see when we work through your accounts.
                    </p>
                </div>

                <div className="my-5">

                    <h3>Attaching relevant electronic files</h3>

                    <p>
                        If you wish to attach files from your computer to the questionnaire (this includes files that
                        you
                        scan
                        and save to your computer, backup of your computerised accounting system, and any other relevant
                        computer files) you can do so by clicking the <strong>Add Files</strong> button at the top right
                        of
                        the
                        screen.
                    </p>

                    <p>
                        Use the <strong>Select Document</strong> button to locate the file(s) on your computer, and the
                        <strong>OK</strong> button to attach them to the questionnaire. The files then appear listed in
                        the
                        left-hand sidebar of the
                        questionnaire.
                    </p>

                    <p>
                        If you upload the wrong file, click on the <strong>x</strong> icon next to the
                        file name to remove it.
                    </p>
                </div>

                <div className="my-5">
                    <h3>Signing the terms of compilation engagement</h3>

                    <p>
                        Once you have finalised the questionnaire, you will be prompted to sign our Terms of Compilation
                        Engagement. Or you can access these directly from your home page by clicking on the underlined
                        "Sign
                        Terms of Engagement" link. We require you to sign off on these Terms before we can commence work
                        on
                        your accounts.
                    </p>

                    <p>
                        You can print the Terms of Compilation Engagement using the <strong>Print
                        and Sign (new tab)</strong> button. This creates a PDF file of the
                        Terms and
                        displays them in a new browser tab so you can print them. Once
                        printed, please sign these Terms and post them to us.
                    </p>


                    {/* TODO only if firm accepts electronic signatures */}

                    {
                        helpData.electronicSignature
                            ? <p>
                                Rather than print the Terms, you can click on the <strong>Electronically
                                Sign</strong> button to indicate your acceptance of our Terms. If you
                                do this,
                                you do not need to print them.
                            </p>
                            : null
                    }
                </div>


                <div className="my-5">
                    <h3>Printing the checklist</h3>

                    <p>
                        Once you have finalised the questionnaire, you will be prompted to print the checklist. This
                        checklist
                        is specific to you. If you do not have a print facility at the time you finalise the
                        questionnaire
                        you
                        can print it later. Also if you misplace the original checklist, don't worry, you can re-print
                        it.
                    </p>

                    <p>
                        The checklist is printed by clicking on the <strong>Print Checklist (new tab)</strong> button.
                        This
                        creates a PDF file of the checklist and display is in a new browser tab so you can print them.
                        Once
                        printed, please go through the checklist and post it to us with the corresponding paperwork.
                    </p>

                    <p>
                        Note that since the content of the checklist is dependent on your answers in the questionnaire,
                        you
                        cannot print the checklist until after you have finalised the questionnaire.
                    </p>
                </div>

                <div className="my-5">
                    <h3>Printing the questionnaire</h3>

                    <p>
                        Once you have finalised the questionnaire you can print a
                        copy of it for your records if you so wish. To do this,
                        go to the home page and click
                        on the underlined link "Fill in Questionnaire" of the
                        completed questionnaire you wish to print (this is the
                        same link you initially clicked to
                        open the questionnaire).
                    </p>

                    <p>You will see that, as the questionnaire is now completed,
                        the link takes you to a PDF version of the
                        questionnaire, which you are able to print
                        or save to your computer.
                    </p>
                </div>

                <div className="my-5">
                    <h3>Changing your log in password/forgotten password</h3>

                    <p>
                        If you wish to change your password, go to the CHANGE PASSWORD page and do so there. The
                        password
                        will be reset for the next time you log in.</p>

                    <p>
                        Should you forget your password please use the 'Forgotten your password' link on the login page.
                        You
                        will be emailed a new password to use to log in. Once you have logged in you may change your
                        password as per above.
                    </p>

                </div>

                {
                    (helpData.freePhone || helpData.phone)
                        ?
                        <div className="my-5">
                            <h3>Further assistance</h3>

                            <p>If the above information has not helped and you require help or have any queries with the
                                operation
                                of the system, please phone us on the following and one of our staff will assist you.
                            </p>
                            <dl>
                                {helpData.phone
                                    ? <React.Fragment>
                                        <dt>Phone</dt>
                                        <dd>{helpData.phone}</dd>
                                    </React.Fragment>
                                    : null
                                }
                                {helpData.freePhone
                                    ? <React.Fragment>
                                        <dt>Free Phone</dt>
                                        <dd>{helpData.freePhone}</dd>
                                    </React.Fragment>
                                    : null
                                }
                            </dl>
                        </div>
                        : null
                }
            </div>
        )
    }
};
