import React, {useRef, useState} from "react";
import {Field, Form} from "react-final-form";
import {QuestionnaireInstance, submitTermsForm} from "../../api/questionnaireAPI";
import SignatureCanvas from "react-signature-canvas";
import {Button, Modal} from "react-bootstrap";
import {Error} from "../../app/App";
import {renderField} from "../login/Login";
import {useDispatch} from "react-redux";
import {fetchQuestionnaires} from "../questionnaireList/questionnaireListSlice";
import {fetchQuestionnaire} from "../questionnaireQuestionnaire/questionnaireSlice";

interface BaseTermsProps {
    questionnaire: QuestionnaireInstance

}

interface NEProps extends BaseTermsProps {
    onSubmit: (e: any) => Promise<any>
}

export interface TFProps extends BaseTermsProps{
    onSuccess: () => void
}

interface SFProps {
    value: string;
    onChange: (value?: string) => void
}

const SignatureField = (props: SFProps) => {
    const ref = useRef<SignatureCanvas>();
    const [changed, onChange] = useState(false);

    const onClear = () => {
        if (ref.current) {
            ref.current.clear();
            onChange(false);
            // set value on clear
            props.onChange(undefined);
        }
    }

    const onUndo = () => {
        const signaturePad = ref.current;
        if (signaturePad) {
            const data = signaturePad.toData();
            if (data) {
                data.pop(); // remove the last dot or line
                signaturePad.fromData(data);

                if (data.length === 0) {
                    // clear if undoing has resulted in an empty data point array
                    onClear();
                }
            }
        }
    }

    const onEnd = () => {
        if (ref.current) {
            // set value to trimmed canvas on end of stroke
            props.onChange(ref.current.getTrimmedCanvas().toDataURL());
        }
    }

    let signatureHelp = null;

    if (!changed) {
        signatureHelp = <div
            className="signature-text"
            unselectable="on">
            Sign Here <i className="fa fa-signature"/>
        </div>
    }

    return (
        <div>
            <div className="form-group">
                <label>Signature (optional)</label>
                <div className="shadow-sm">
                    <div className="signature-wrapper">
                        {signatureHelp}

                        <button
                            type="button"
                            className="btn btn-link signature-clear-btn border-bottom" disabled={!changed}
                            onClick={onClear} aria-label="clear">
                            <i className="fa fa-remove"/>
                        </button>

                        <button
                            type="button"
                            className="btn btn-link signature-undo-btn" disabled={!changed}
                            onClick={onUndo} aria-label="undo">
                            <i className="fa fa-undo"/>
                        </button>


                        <div className="signature-outer">
                            <SignatureCanvas
                                minWidth={0.3}
                                maxWidth={1.5}
                                onBegin={() => onChange(true)}
                                onEnd={onEnd}
                                //@ts-ignore
                                ref={ref}
                                canvasProps={{width: 600, height: 100, className: 'signature-main'}}
                            />
                        </div>
                    </div>
                </div>

                {/*<small className="form-text text-muted">*/}
                {/*    Enter signature above...etc...*/}
                {/*</small>*/}

            </div>
        </div>

    );
}

interface ESTMProps extends NEProps {
    show: boolean;
    onHide: () => void;
}

const ElectronicSignatureTermsModal = ({show, onHide, questionnaire, onSubmit}: ESTMProps) => {
    return (
        <Form
            initialValues={{
                questionnaireId: questionnaire.instanceId
            }}
            onSubmit={onSubmit}
            subscription={{submitting: true, pristine: true, values: true}}
        >
            {({handleSubmit, submitting}) => {
                return (
                    <Modal show={show} onHide={onHide}>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Sign Terms of Engagement
                            </h5>
                        </div>
                        <Modal.Body>
                            <form onSubmit={handleSubmit}>
                                <Field name="questionnaireId" component="input" type="hidden"/>


                                <Field
                                    label="Name (required)"
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder="Enter name"
                                    component={renderField}
                                />

                                <Field
                                    label="Position (required)"
                                    id="position"
                                    name="position"
                                    type="text"
                                    placeholder="Enter position"
                                    component={renderField}
                                />

                                <Field name="signature">
                                    {props => (
                                        <div>
                                            <SignatureField
                                                // name={props.input.name}
                                                value={props.input.value}
                                                onChange={props.input.onChange}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </form>
                        </Modal.Body>

                        <Modal.Footer>
                            {/*TODO loading indicator*/}
                            <Button variant="secondary" onClick={onHide}>Close</Button>
                            <Button disabled={submitting} onClick={() => handleSubmit()} variant="primary">Sign</Button>
                        </Modal.Footer>
                    </Modal>
                )
            }}
        </Form>
    )
}

const NonElectronicSignForm = ({questionnaire, onSubmit}: NEProps) => {
    return (
        <Form
            initialValues={{
                questionnaireId: questionnaire.instanceId
            }}
            onSubmit={onSubmit}
            subscription={{submitting: true, pristine: true, values: true}}
        >
            {({handleSubmit}) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Field name="questionnaireId" component="input" type="hidden"/>

                        <button type="submit" className="btn btn-success btn-lg">
                            Terms PDF
                        </button>
                    </form>
                )
            }}
        </Form>
    )
}

export const TermsButton = ({questionnaire, onSuccess}: TFProps) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const onSubmit = (electronic: boolean) => async (e: any) => {
        let errors: { [key: string]: string } = {};

        try {
            const data = await submitTermsForm(e, electronic);
            data && data.pdfLocation && window.open(data.pdfLocation, '_blank');

            dispatch(fetchQuestionnaire(questionnaire.instanceId, onSuccess));


        } catch (e) {
            // logout if 401
            if (e.response.status === 422) {
                if (e.response && Array.isArray(e.response.data)) {

                    const data: Error[] = e.response.data;

                    data.forEach((error) => {
                        errors[error.field] = error.message;
                    })
                }
                return errors;
            }
        }
    };

    let button;
    if (questionnaire.allowElectronicSignature) {
        button = <React.Fragment>
            <button className="btn btn-success btn-lg" onClick={() => setShow(true)} type="button">
                Sign
            </button>
            <ElectronicSignatureTermsModal onSubmit={onSubmit(true)} show={show} onHide={() => setShow(false)} questionnaire={questionnaire}/>
        </React.Fragment>;
    } else {
        button = <NonElectronicSignForm onSubmit={onSubmit(false)} questionnaire={questionnaire}/>
    }

    return (
        button
    )
}
