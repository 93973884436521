import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {getQuestionnaireSections, postAcceptChecklist, Section} from "../../api/questionnaireAPI";

interface QuestionnaireState {
    finalization: boolean;
    sections: Section[];
    finalizationSection?: Section;
    error: string | null;
    isLoading: boolean;
    finalizationSectionId?: number;
}

let questionnaireDisplayInitialState: QuestionnaireState = {
    finalization: false,
    sections: [],
    // TODO should be false
    isLoading: true,
    error: null,
};

function startLoading(state: QuestionnaireState) {
    state.isLoading = true
}

function loadingFailed(state: QuestionnaireState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload
}

function questionnaireEnd(state: QuestionnaireState) {
    state.isLoading = false;
}

interface QuestionnaireSectionsSuccessPayload {
    sections: Section[];
}

const questionnaireListSlice = createSlice({
    name: 'questionnaires',
    initialState: questionnaireDisplayInitialState,
    reducers: {
        setQuestionnaireEnd: questionnaireEnd,

        getQuestionnaireSectionsStart: startLoading,
        getQuestionnaireSectionsSuccess(state, {payload}: PayloadAction<QuestionnaireSectionsSuccessPayload>) {
            const {sections} = payload;

            state.sections = sections;
            state.isLoading = false;
            state.error = null;
        },

        setFinalizationSectionId(state, {payload}: PayloadAction<number>) {
            state.finalizationSectionId = payload;
        },

        getQuestionnaireSectionsFailure: loadingFailed
    }
});


export const {
    getQuestionnaireSectionsStart,
    getQuestionnaireSectionsSuccess,
    getQuestionnaireSectionsFailure,
} = questionnaireListSlice.actions;

export const fetchSections = (
    questionnaireId: number,
    numSections: number,
    callback?: () => any
): AppThunk => async (dispatch, getState) => {
    const state = getState();

    try {
        dispatch(getQuestionnaireSectionsStart());
        const sections = await getQuestionnaireSections(questionnaireId, numSections);
        dispatch(getQuestionnaireSectionsSuccess({
            sections
        }));
        callback && callback();
    } catch (err) {
        // logout if 401
        if (err.response && err.response.status === 403) {
            // dispatch(logout());
        } else {
            dispatch(getQuestionnaireSectionsFailure(err.toString()))
        }
    }
};

export default questionnaireListSlice.reducer;
