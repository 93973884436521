import axios from "../utils/axios";
import qs from "qs";
import axiosStatic, {Canceler} from "axios"
import {LoginDetails} from "./loginApi";

export interface QuestionQuery {
    queryText?: string;
    questionnaireId: number;
    questionId?: number;
}

export interface Table {
    name: string;
    id: number;
    title: string;
    order: number;
}

export interface Question {
    id: number;
    name: string;
    type: {
        name: 'LABEL' | 'TEXT' | 'SELECT';
        questionOptions: {
            name: string;
            value: string;
        }[]
    },
    questionText: string;
    helpText?: string;
    default?: string;
    order: number;
    query?: string;
}

export interface QuestionRule {
    hide: string[];
    // TODO typing for JSONlogic object?
    check: object;
}

export interface Total {
    totalQuestion: string;
    questions: string[];
}


//TODO change to load id and name only? this is all that is required for section navigation data
//  rename
export interface Section {
    id: number;
    name: string;
    isFinal: boolean;
    isValid: boolean;
}

export interface QuestionnaireInstance {
    lastSectionIndex: number;
    filesAllowed: boolean;
    hasPdf: boolean;
    instanceId: number;
    id: number;
    name: string;
    organisationName: string;
    terms: string;
    // TODO these should be abstracted
    checklistcomplete: boolean;
    termsofengagementcomplete: boolean;
    jobcomplete: boolean;
    listName: string;
    termsPdf?: string;
    questionnairePdf?: string;
    checklistPdf?: string;
    allowElectronicSignature: boolean;
    clientnotes?: string;
}

export interface TableInnerQuestion extends Question {
    totaled: boolean;
}

export interface TableQuestion extends Table {
    navLabel: string;
    inline: boolean;
    questions: TableInnerQuestion[];
}

export interface SectionAnswers {
    [key: string]: string
}

export interface StockTableSection {
    header: string;
    questions: Question[];
}

export interface StockTable extends Table {
    sections: StockTableSection[];
}

export interface Derived {
    attribute: string;
    dependents: string[];
    rule: object;
}
//TODO add removed attrributes from Section interface
export interface SectionData {
    questions: Question[];
    answers: SectionAnswers;
    rules: QuestionRule[];
    tableQuestions: TableQuestion[];
    stockTable: StockTable[];
    derived: Derived[];
    hasAnswers: boolean;
    totals: Total[];
    notes?: string;
    numericQuestionNames: string[];
}

export interface Questionnaires extends Array<QuestionnaireInstance> {
}

export interface HelpData {
    electronicSignature: boolean;
    phone?: string;
    freePhone?: string;
}

export interface PDFData {
    pdfLocation?: string
}

export interface QuestionnaireDocument {
    size: number,
    id: number;
    downloadLink: string;
    name: string;
    deleteLink: string;
}

export interface SectionStatus {
}

const CancelToken = axiosStatic.CancelToken;
let cancel: Canceler | undefined;



export async function getQuestionnaires(all: boolean) {
    const params = qs.stringify({
        all,
    })

    const url = `site` + `?all=${all ? '1' : '0'}`;

    cancel && cancel();

    const {data} = await axios.get<Questionnaires>(url, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
    });

    return data
}

export async function postFiles(files: File[], questionnaireId: number, onProgress: (progress: number) => void) {
    let url = `site/upload/${questionnaireId}`;


    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        formData.append(`file[${i}]`, file);
    }


    const {data} = await axios.post<QuestionnaireDocument[]>(url, formData,  {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress(percentCompleted);
        }
    });

    return data;
}


export async function getQuestionnaireSections(questionnaireId: number, numSections: number) {
    const params = qs.stringify({
        questionnaireId,
        numSections
    })

    let url = `site/sections?questionnaireId=${questionnaireId}&numSections=${numSections}`;

    const {data} = await axios.get<Section[]>(url);
    return data
}

export async function getQuestionnaire(id: number) {
    const url = `site/questionnaire/${id}`;
    const {data} = await axios.get<QuestionnaireInstance>(url);
    return data
}

export async function getQuestionnaireChecklist(questionnaireId: number) {
    let url = `site/checklist?questionnaireId=${questionnaireId}`;

    const {data} = await axios.post<string[]>(url);
    return data
}


export async function getSectionQuestions(questionnaireId: number, sectionId: number) {
    let url = `site/section-questions?questionnaireId=${questionnaireId}&sectionId=${sectionId}`;


    const {data} = await axios.get<SectionData>(url);
    return data
}

export async function saveQuestionnaireSection(questionnaireId: number, sectionId: number, answers: SectionAnswers) {
    let url = `site/save?questionnaireId=${questionnaireId}&sectionId=${sectionId}`;

    return axios.post(url, answers);
}

export async function saveQuestionQuery(questionQuery: QuestionQuery) {
    let url = `site/query`;

    const {data} = await axios.post(url, questionQuery);
    return data
}

export async function deleteQuestionQuery(questionQuery: QuestionQuery) {
    let url = `site/delete-query?questionnaireId=${questionQuery.questionnaireId}&questionId=${questionQuery.questionId}`;

    const {data} = await axios.post(url);
    return data
}

export async function submitTermsForm(formData: object, electronicSign = true) {
    const {data} = await axios.post<PDFData>(`site/sign-terms?electronic=${electronicSign ? '1' : '0'}`, formData);
    return data;
}

export async function getHelpData() {
    let url = `site/help-data`;
    const {data} = await axios.get<HelpData>(url);
    return data
}

export async function transitionQuestionnaireToFinished(instanceId: number) {
    let url = `site/finalise?questionnaireId=${instanceId}`;

    const {data} = await axios.post(url, {});
    return data
}


export async function postAcceptChecklist(questionnaireId: number) {
    let url = `site/accept-checklist/${questionnaireId}`

    const {data} = await axios.post<PDFData>(url)

    return data.pdfLocation;
}

export async function getDocuments(questionnaireId: number) {
    let url = `site/questionnaire-documents/${questionnaireId}`

    const {data} = await axios.get<QuestionnaireDocument[]>(url)

    return data;
}

export async function deleteQuestionnaireDocument(questionnaireId: number, document: QuestionnaireDocument) {
    let url = `site/delete-file/${document.id}`

    const {data} = await axios.post<void>(url)

    return data;
}