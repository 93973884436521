import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {getQuestionnaireChecklist, postAcceptChecklist} from "../../api/questionnaireAPI";
import {History} from "history";

// TODO extract to base loading / error interface

interface QuestionnaireChecklistState {
    error: string | null
    isLoading: boolean
    checklist: string[]
}

let questionnaireDisplayInitialState: QuestionnaireChecklistState = {
    isLoading: true,
    error: null,
    checklist: []
};

function startLoading(state: QuestionnaireChecklistState) {
    state.isLoading = true
}

function loadingFailed(state: QuestionnaireChecklistState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload
}

const questionnaireChecklistSlice = createSlice({
    name: 'questionnaires',
    initialState: questionnaireDisplayInitialState,
    reducers: {
        getQuestionnaireChecklistStart: startLoading,


        getQuestionnaireChecklistSuccess(state, {payload}: PayloadAction<string[]>) {
            state.checklist = payload;
            state.isLoading = false;
            state.error = null;
        },

        getQuestionnaireChecklistFailure: loadingFailed
    }
});

export const {
    getQuestionnaireChecklistSuccess,
    getQuestionnaireChecklistStart,
    getQuestionnaireChecklistFailure,
} = questionnaireChecklistSlice.actions;

// fetch checklist
export const fetchChecklist = (
    questionnaireId: number,
    history: History
): AppThunk => async (dispatch) => {
    try {
        dispatch(getQuestionnaireChecklistStart());
        const checklist = await getQuestionnaireChecklist(questionnaireId);
        dispatch(getQuestionnaireChecklistSuccess(checklist));

        await getQuestionnaireChecklist(questionnaireId);
    } catch (err) {
        // handle forbidden
        dispatch(getQuestionnaireChecklistFailure(err.toString()))
    }
};


export const acceptChecklist = (
    onSuccess: () => void,
    questionnaireId: number
): AppThunk => async (dispatch) => {
    try {
        // dispatch(getQuestionnaireSectionsStart());
        const pdfLocation = await postAcceptChecklist(questionnaireId);
        onSuccess();
        pdfLocation && window.open(pdfLocation, '_blank');

        // dispatch(getQuestionnaireSectionsSuccess({
        //     sections
        // }));
    } catch (err) {
        // logout if 401
        if (err.response && err.response.status === 403) {
            // dispatch(logout());
        } else {

            // dispatch(getQuestionnaireChecklistFailure(err.toString()))
        }
    }
};
export default questionnaireChecklistSlice.reducer;
