import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/rootReducer";
import {fetchPasswordRule} from "./loginSlice";
import {MainLoader} from "../../components/LoadingIndicators";
import {useHistory} from "react-router";


export const PasswordInfo = () => {
    const {isLoading, error, passwordRule} = useSelector((state: RootState) => state.login);

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        // load initial section state
        dispatch(fetchPasswordRule(history));
    }, [dispatch]);

    if (isLoading) {
        return <MainLoader/>
    } else if (error) {
        return null
    } else {
        // guard from password rule initially not being loaded
        if (!passwordRule) {
            return null;
        }
        const {length, digits, specialchars} = passwordRule!;

        return (
            <div className="alert alert-secondary">
                {length ? <p>
                    Your password must be at least <b>{length}</b> characters long.
                </p> : null}

                {digits ? <p>
                    Your password must contain at least <b>{digits}</b> numbers.
                </p> : null}

                {specialchars ? <p>
                    Your password must contain at least <b>{specialchars}</b> non-alphabetic non-numeric characters.
                </p> : null}

                <p>
                    Your password must not be the same as your username.
                </p>
            </div>
        )
    }
};
