import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";

import {
    Derived,
    getSectionQuestions,
    Question,
    QuestionRule,
    SectionAnswers,
    SectionData,
    StockTable,
    TableQuestion,
    Total
} from "../../api/questionnaireAPI";

interface QuestionnaireSectionState {
    questions: Question[];
    derived: Derived[];
    tableQuestions: TableQuestion[];
    rules: QuestionRule[];
    error: string | null
    isLoading: boolean;
    isSaving: boolean;
    showSaved: boolean;
    changedAnswers?: SectionAnswers;
    answers: SectionAnswers;
    next: boolean;
    sectionLoad?: number;
    goHome: boolean;
    numberTables: StockTable[];
    numericQuestionNames: string[];
    notes?: string;
    totals: Total[];
}

let questionnaireSectionInitialState: QuestionnaireSectionState = {
    numericQuestionNames: [],
    numberTables: [],
    questions: [],
    derived: [],
    answers: {},
    tableQuestions: [],
    rules: [],
    goHome: false,
    // TODO should be false
    isLoading: true,
    isSaving: false,
    error: null,
    showSaved: false,
    next: false,
    totals: []

};

function startLoading(state: QuestionnaireSectionState) {
    state.isLoading = true
}

function loadingFailed(state: QuestionnaireSectionState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload;

    state.next = false;
}


function startSaving(state: QuestionnaireSectionState) {
    state.isSaving = true
}

function savingFailed(state: QuestionnaireSectionState, action: PayloadAction<string>) {
    state.isSaving = false;
    state.error = action.payload
}

interface SectionTargetData {
    sectionIndex: number,
}

const questionSectionSlice = createSlice({
    name: 'questions',
    initialState: questionnaireSectionInitialState,
    reducers: {
        dismissSaved(state) {
            state.showSaved = false;
        },
        saveSectionQuestionsStart: startSaving,
        saveSectionQuestionsSuccess(state) {
            state.showSaved = true;
            state.isSaving = false;
            state.error = null;
        },
        saveSectionQuestionsFailed: savingFailed,
        setSectionTarget(state, {payload}: PayloadAction<SectionTargetData>) {
            state.sectionLoad = payload.sectionIndex;
        },

        setGoHome(state, {payload}: PayloadAction<boolean>) {
            state.goHome = payload;
        },
        getSectionQuestionsStart: startLoading,

        getSectionQuestionsSuccess(state, {payload}: PayloadAction<SectionData>) {
            const {answers, questions} = payload;
            state.questions = questions;
            state.tableQuestions = payload.tableQuestions;
            state.rules = payload.rules;
            state.numericQuestionNames = payload.numericQuestionNames;
            state.notes = payload.notes;
            state.totals = payload.totals;


            state.isLoading = false;
            state.error = null;

            // assign default answers from questions if an answer hasn't been provided and there is a default
            questions.forEach((question) => {
                if (!answers[question.name] && question.default) {
                    answers[question.name] = question.default;
                }
            });

            state.answers = answers;
            state.derived = payload.derived;
            state.numberTables = payload.stockTable;

            state.next = false;
            state.sectionLoad = undefined;
        },
        getSectionQuestionsFailure: loadingFailed,
    }
});

export const {
    getSectionQuestionsStart,
    getSectionQuestionsSuccess,
    getSectionQuestionsFailure,
    setSectionTarget,
    setGoHome
} = questionSectionSlice.actions;


// fetch questions for a particular section
export const fetchQuestions = (
    questionnaireId: number,
    sectionId: number,
): AppThunk => async (dispatch) => {

    try {
        dispatch(getSectionQuestionsStart());
        const questionAnswers = await getSectionQuestions(questionnaireId, sectionId);
        dispatch(getSectionQuestionsSuccess(questionAnswers));
    } catch (err) {
        // logout if 401

        dispatch(getSectionQuestionsFailure(err.toString()))
    }
};

export default questionSectionSlice.reducer;
