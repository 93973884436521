import Decimal from "decimal.js";

type QuestionCalculations = string[];

export const calcFarmDwellingExpenses = (farmDwellingPercentage: string, farmHouseArea: string, farmBusinessArea: string, percentageBusinessAreaUsed: string, farmOfficeArea?: string): Decimal => {
    let result = new Decimal(0);

    // if (farmDwellingPercentage.toNumber() < 20) {
        let proportion = new Decimal(0);

        // prevent div by 0
        if ((new Decimal(farmHouseArea ?? 0)).toNumber() > 0) {
            proportion = new Decimal(new Decimal(farmBusinessArea ?? 0).div(farmHouseArea));

            if (farmOfficeArea) {
                proportion.add((new Decimal(farmOfficeArea)).div(farmHouseArea));
            }
        }

        result = proportion.mul((new Decimal(percentageBusinessAreaUsed ?? 0).div(100))).mul(100)
    // }

    return result;
}


export function businessPercentage(allValues: { [key: string]: string }) {
    const PDUBusinessArea = parseFloat(allValues['PDUBusinessArea']);
    const PDUHomeArea = parseFloat(allValues['PDUHomeArea']);

    let val = 0;

    if (!isNaN(PDUBusinessArea) && !isNaN(PDUHomeArea)) {
        val = 100 * (PDUBusinessArea / PDUHomeArea);
    }


    if (!isFinite(val)) {
        val = 0;
    }
    return val;
}
