import React, {useEffect, useState} from "react";
import {useFormState} from "react-final-form";
import {TableCard} from "./TableCard";
import {QuestionField} from "./QuestionField";
import {TableProps} from "./Table";

const CheckOne = (props: { onClick: () => any, questionsExist: boolean }) => {
    const [added, setAdded] = useState(false);
    useEffect(() => {
        if (!props.questionsExist && !added) {
            setTimeout(() => props.onClick(), 0)
            setAdded(true);
        }
    }, []);

    return null;
}

export const PaginatedTable = (props: TableProps) => {
    // @ts-ignore
    const {fields, meta: {error, submitFailed}, tableQuestion, derivedQuestions, instanceId} = props;

    const values = useFormState();
    let navLabel = tableQuestion.navLabel;

    const onClick = () => {
        const defaultAnswers: { [key: string]: string } = {};

        tableQuestion.questions.forEach((question) => {
            if (question.default) {
                defaultAnswers[question.name] = question.default;
            }

        })

        fields.push(defaultAnswers)
    };

    const questionsExist = !!values.values[tableQuestion.name];

    return (
        <div className="mb-4">

            <CheckOne questionsExist={questionsExist} onClick={onClick}/>
            <h4>{tableQuestion.title}</h4>

            {submitFailed && error && <span>{error}</span>}
            {
                // @ts-ignore
                fields.map((member, index) => {
                    const num = index + 1;
                    const header = navLabel ? `${navLabel} #${num}` : num;

                    return (
                        <TableCard
                            header={header}
                            key={index}
                        >
                            <div>
                                <div>
                                    {props.tableQuestion.questions.map((question) =>
                                        <QuestionField
                                            noQuery
                                            numeric={false}
                                            isDerived={derivedQuestions[question.name]}
                                            instanceId={instanceId}
                                            namePrefix={member}
                                            question={question}
                                        />
                                    )}
                                </div>
                            </div>

                            {/* TODO this delete button shouldn't be here*/}

                            <div className="mt-1">
                                <button
                                    className="btn btn-danger float-right"
                                    type="button"
                                    title="Remove Member"
                                    onClick={() => {
                                        fields.remove(index);
                                    }}
                                >
                                    Delete {navLabel}
                                </button>
                            </div>
                        </TableCard>
                    );
                })}

            <div className="pt-1">
                <button type="button" className="btn btn-primary " onClick={onClick
                }>
                    Add {navLabel}
                </button>
            </div>
        </div>
    )
};
