import React from "react";
import {Field, Form} from "react-final-form";
import {ChangePasswordDetails, postChangePassword} from "../../api/loginApi";
import {HeadingContainer} from "../../components/HeadingContainer";
import {renderField} from "./Login";
import {useDispatch} from "react-redux";
import {PasswordInfo} from "./PasswordInfo";

interface PRFProps {
    onSuccess: () => void
}

export const PasswordChangeForm = ({onSuccess}: PRFProps) => {
    const dispatch = useDispatch();


    const onPasswordChange = async (changePasswordDetails: ChangePasswordDetails) => {
        let errors: { [key: string]: string } = {};

        await postChangePassword(changePasswordDetails).then((e) => {
            const data = e.data;
            //     TODO set success

            onSuccess();
        }).catch(e => {
            if (e.response) {
                const data = e.response.data;

                if (e.response.status === 422) {
                    data.map((error: { field: string, message: string }) => {
                        errors[error.field] = error.message;
                    })
                }
                //    TODO other errors
            }
        });

        return errors;
    };

    return (
        <div className="container">
            <HeadingContainer>
                <h2>Change Password</h2>
            </HeadingContainer>

            <PasswordInfo/>
            {/*TODO need username */}
            <Form

                // initialValues={{username: user!.username}}
                onSubmit={onPasswordChange}
                render={({handleSubmit, submitting, pristine, invalid, values, errors}) => (

                    <form onSubmit={handleSubmit}>
                        <Field
                            label="Current Password"
                            id="new-password-input"
                            name="password"
                            component={renderField}
                            type="password"
                            placeholder="Current Password"
                            className="form-control"
                        />
                        <Field
                            label="New Password"
                            id="new-password-input"
                            name="newPassword"
                            component={renderField}
                            type="password"
                            placeholder="New Password"
                            className="form-control"
                        />
                        <Field
                            label="Confirm New Password"
                            id="new-password-input"
                            name="confirmNewPassword"
                            component={renderField}
                            type="password"
                            placeholder="Confirm New Password"
                            className="form-control"
                        />

                        <div>
                            <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
                                Change Password
                            </button>
                        </div>
                    </form>
                )}
            />
        </div>
    )
}
