import {Action, configureStore} from '@reduxjs/toolkit'
import {ThunkAction} from 'redux-thunk'
import rootReducer, {RootState} from './rootReducer'
import {questionnaireSection} from "../services/questionnaire-section";
import {setupListeners} from "@reduxjs/toolkit/query";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => (
        getDefaultMiddleware().concat(questionnaireSection.middleware)
    )
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store;



