import React, {useEffect} from "react";
import {
    QuestionnaireSection,
    SectionSelectorSidebar,
    SectionSelectorTopBar
} from "../questionnaireSection/QuestionnaireSection";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {fetchSections} from "./questionnaireDisplaySlice";
import {RootState} from "../../app/rootReducer";
import {MainLoader} from "../../components/LoadingIndicators";
import {setSectionTarget} from "../questionnaireSection/questionnaireSectionSlice";
import {match, useHistory, useRouteMatch} from "react-router-dom";
import {QuestionnaireHeading} from "./QuestionnaireHeading";
import {finaliseQuestionnaire} from "../questionnaireQuestionnaire/questionnaireSlice";
import {QuestionnaireFiles} from "../questionnaireQuestionnaire/QuestionnaireFiles";
import {QTProps} from "./QuestionnaireTerms";


interface QDProps extends QTProps {
    onSectionChange: (selectedIndex: string | number) => void
    termsLink: string
    generateSectionLink: (sectionIndex: number) => string
}

export const QDSID = (props: QDProps) => {
    const {questionnaire, checklistLink, termsLink} = props;

    const history = useHistory();

    if (props.questionnaire.jobcomplete) {
        // don't allow user to attempt to edit a completed questionnaire
        history.replace('/');
    }

    const match: match<{ selectedSection?: string }> = useRouteMatch();

    let sectionIndex = 0;

    if (match.params.selectedSection) {
        sectionIndex = +match.params.selectedSection;
    }

    return (
        <QuestionnaireDisplay selectedSection={sectionIndex} {...props}/>
    )
}

export const QuestionnaireDisplay = ({
                                         generateSectionLink,
                                         checklistLink,
                                         termsLink,
                                         questionnaire,
                                         selectedSection,
                                         onSectionChange
                                     }: QDProps & { selectedSection: number }) => {
    // TODO move to redux
    const dispatch = useDispatch();

    const {
        isSaving
    } = useSelector((state: RootState) => state.questionnaireSection);

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;


    const {
        isLoading,
        error,
        sections,
        finalization,
    } = useTypedSelector((state: RootState) => state.questionnaireDisplay);


    const history = useHistory();
    const instanceId = questionnaire.instanceId;


    useEffect(() => {
        // load initial section state
        dispatch(fetchSections(instanceId, selectedSection + 1));
    }, [dispatch, instanceId, selectedSection]);


    let sectionDisplay = null, content = null, topSectionSelector = null, submit: (() => boolean) | null = null;


    if (isLoading && sections.length === 0) {
        // show loading indicator if loading AND no sections previously loaded
        content = <MainLoader/>
    } else if (error) {
        content = <div>error</div>
    } else {
        // TODO handle 0 sections
        let nextSection = null;
        let previousSection: number | undefined;

        let heading = null;

        if (questionnaire) {
            heading = <QuestionnaireHeading questionnaire={questionnaire}/>
        }

        const section = sections[selectedSection];

        let formId = "section-form";

        submit = () => {
            // { cancelable: true } required for Firefox
            // https://github.com/facebook/react/issues/12639#issuecomment-382519193

            const form = document
                .getElementById(formId);

            if (form) {
                return form
                    .dispatchEvent(new Event('submit', {cancelable: true}))
            }
            // TODO need way of changing section if form not loaded
            return false;
        };
        let selectPrevious: (() => void) | undefined = undefined;
        let selectNext: (() => void) | undefined = undefined;
        const hasPrevious = selectedSection > 0;

        if (section) {
            previousSection = hasPrevious ? selectedSection - 1 : undefined;


            if (previousSection !== undefined) {
                selectPrevious = () => setSelectedSection(previousSection!);
            }

            selectNext = () => {
                if (section.isFinal) {
                    // current section is final
                    dispatch(finaliseQuestionnaire(instanceId, (questionnaire) => {
                        if (questionnaire.termsofengagementcomplete) {
                            history.push(checklistLink)
                        } else {
                            history.push(termsLink)
                        }
                    }));

                } else {
                    setSelectedSection(selectedSection + 1);
                }

            }

            sectionDisplay =
                <QuestionnaireSection
                    onNext={selectNext}
                    onPrevious={selectPrevious}
                    termsLink={termsLink}
                    onRedirect={onSectionChange}
                    formId={formId}
                    selectedSection={selectedSection}
                    questionnaire={questionnaire}
                    section={section}
                    loadingSections={isLoading}
                    previousSection={previousSection}

                    /* TODO need to get whether section is final from host */
                    generateLink={generateSectionLink}
                />;

        }


        const setSelectedSection = (sectionIndex: number) => {
            dispatch(setSectionTarget({
                sectionIndex
            }));

            submit && submit();
        };


        topSectionSelector =
            <div className="d-sm-block d-md-none d-print-none">
                <SectionSelectorTopBar
                    saving={isSaving}

                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    sections={sections}
                    loading={isLoading}
                    finalization={finalization}

                    hasNext={true}
                    hasPrevious={hasPrevious}
                    onPrevious={selectPrevious}
                    onNext={selectNext}
                />
            </div>;


        content = <div className="row">
            <nav className="col-md-3 col-lg-2 d-none d-md-block bg-light sidebar d-print-none">
                <SectionSelectorSidebar
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                    sections={sections}
                    loading={isLoading}
                    finalization={finalization}
                    questionnaire={questionnaire}
                />
            </nav>

            <main className="col-md-9 col-lg-10 ml-sm-auto px-3" style={{
                paddingBottom: 90
            }}>
                {heading}

                <div className="question-input">
                    <QuestionnaireFiles questionnaire={questionnaire}/>
                </div>


                {isLoading ? <MainLoader/> : sectionDisplay}
            </main>
        </div>;


    }

    return (
        // TODO perhaps section selector should be here

        <div>
            {topSectionSelector}
            <div className="container-fluid sidebar-main">


                {/*<button className="btn btn-danger" onClick={onCancel}>Cancel</button>*/}
                {content}


            </div>

        </div>
    )
};
