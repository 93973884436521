import React, {useState} from "react";
import {useSelector} from "react-redux";
import {QSProps} from "./QuestionnaireSection";
import {Question, SectionStatus, StockTable, TableQuestion} from "../../api/questionnaireAPI";
import {TextDisplay} from "../../components/TextDisplay";
import {QuestionField} from "./QuestionField";
import {NumberTable} from "./NumberTable";
import {Form} from "react-final-form";
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import {RootState} from "../../app/rootReducer";
import createDecorator, {Calculation} from "final-form-calculate";
import AutoSave from "./AutoSave";
import jsonLogic from "../../utils/jsonLogic";
import {SectionFormButtons} from "./SectionFormButtons";
import {Link} from "react-router-dom";
import {Table} from "./Table";
import {PaginatedTable} from "./PaginatedTable";
import {AnimatePresence, motion} from "framer-motion";
import {businessPercentage, calcFarmDwellingExpenses} from "../../utils/legacyCalculations";
import Decimal from "decimal.js";
import {useGetSectionStatusQuery} from "../../services/questionnaire-section";

interface SectionFormProps extends QSProps {
    tableQuestions: TableQuestion[];
    numberTables: StockTable[];
    questions: Question[];
    onSubmit: (sectionAnswers: any) => any;
}


interface SIProps {
    saving: boolean
}

//
// const SavingIndicator = ({saving}: SIProps) => {
//     return (
//         <div className="sticky-top"
//     )
// }

export const SectionForm = (props: SectionFormProps) => {
    const {
        formId,
        previousSection,
        section,
        tableQuestions,
        questions,
        numberTables,
        onNext,
        onPrevious,
        questionnaire,
        generateLink,

    } = props;

    const {
        answers,
        derived,
        rules,
        numericQuestionNames,


totals,

        notes
    } = useSelector((state: RootState) => state.questionnaireSection);

    const {
        isFinalizing
    } = useSelector((state: RootState) => state.questionnaire);


    const [noAutoSave, setNoAutoSave] = useState(false);
    const [hiddenQuestions, setHiddenQuestions] = useState<{ [key: string]: boolean }>({});

    const {sections} = useSelector((state: RootState) => state.questionnaireDisplay);

    const {id, name} = section;
    const questionsHide: { [key: string]: boolean } = {};

    // filter questions that appear in the questionsHide object
    let questionsDisplay: Question[] = questions.filter(({name}) => !questionsHide[name]);

    let submit: () => any;

    // TODO all these calculations should be generated server-side


    let pduHomeOfficeUse = (ignoredValue: string, allValues: AllValues) => {

        let businessPercentageVal = businessPercentage(allValues);

        let sum = 0;
        [
            allValues["PDURates"],
            allValues["PDUWaterRates"],
            allValues["PDUMortgageInterest"],
            allValues["PDUElectricity"],
            allValues["PDURent"],
            allValues["PDUInsurance"],
            allValues["PDURepairs"],
            allValues["PDUOther"]
        ].forEach((value) => {
            let num = parseFloat(value);

            if (!isNaN(num)) {
                sum += num;
            }
        });

        let PDUBusinessTolls = allValues["PDUBusinessTolls"];
        let PDUTelephone = allValues["PDUTelephone"];
        let PDUInternet = allValues["PDUInternet"];

        if (businessPercentageVal == 0) {
            businessPercentageVal = 100;
        }

        const result = (sum * (businessPercentageVal / 100)) + parseFloat(PDUBusinessTolls) + ((parseFloat(PDUInternet) * 0.5)) + ((parseFloat(PDUTelephone) * 0.5));

        if (isNaN(result)) {
            return 0;
        }

        return result;
    };

    const uneditable: { [key: string]: boolean } = {'PDUHomeOfficeUse': true, 'PDUBusinessAreaPercentage': true};

    // @ts-ignore
    const calculations: Calculation[] = totals.map((section) => {

        uneditable[section.totalQuestion] = true;

        return {
            field: section.questions,
            updates: {
                // @ts-ignore
                [section.totalQuestion]: (ignoredValue, allValues: AllValues) => {
                    let sum = 0;

                    section.questions.forEach((question) => {
                        let value = parseFloat(allValues[question]);


                        if (!isNaN(value)) {
                            sum += value;
                        }
                    })

                    if (isNaN(sum)) {
                        return 0;
                    }

                    return `${sum}`;
                }
            }
        }
    })

    calculations.push({
        field: ['PDUBusinessArea', 'PDUHomeArea'],
        updates: {
            // @ts-ignore
            'PDUBusinessAreaPercentage': (ignoredValue, allValues: AllValues) => {
                let val = businessPercentage(allValues);


                return `% ${val.toFixed(2)}`;
            }
        },

    });
    calculations.push({
        field: [
            "PDURates",
            "PDUWaterRates",
            "PDUMortgageInterest",
            "PDUElectricity",
            "PDURent",
            "PDUInsurance",
            "PDURepairs",
            "PDUOther",
            "PDUBusinessAreaPercentage",
            "PDUBusinessTolls",
            "PDUTelephone",
            "PDUInternet"
        ],
        // @ts-ignore
        updates: {
            // @ts-ignore

            'PDUHomeOfficeUse': pduHomeOfficeUse
        },

    });

    type AllValues = {
        [Key: string]: string
    }

    derived.forEach((derivedRule) => {
        uneditable[derivedRule.attribute] = true;

        calculations.push({
            field: derivedRule.dependents,
            // @ts-ignore
            updates: {
                [derivedRule.attribute]: (ignoredValue, allValues: AllValues) => {
                    return jsonLogic.apply(derivedRule.rule, allValues);
                }
            }
        })
    });


    numberTables.forEach((table) => {
        table.sections.forEach(({questions}) => {
            const totalQuestions: Question[] = [];
            const otherQuestions: string[] = [];

            questions.forEach((question) => {
                const isTotalQuestion = question.questionText.toLowerCase() === 'total';

                if (isTotalQuestion) {
                    totalQuestions.push(question);
                } else {
                    otherQuestions.push(question.name);
                }
            });


            totalQuestions.forEach((totalQuestion) => {
                calculations.push({
                    field: otherQuestions,
                    // @ts-ignore
                    updates: {
                        [totalQuestion.name]: (ignoredValue, allValues: AllValues) => {
                            const otherQuestionsValues = otherQuestions.map((question) => allValues[question]);

                            return otherQuestionsValues.reduce((sum, value) => sum + Number(value || 0), 0)
                        }
                    }
                })
            });

        })
    });
    // TODO should be extracted out to separate component

    const calculator = createDecorator(...calculations);

    return (
        <Form
            mutators={{...arrayMutators}}
            initialValues={answers}
            onSubmit={props.onSubmit}
            decorators={[calculator]}
            subscription={{submitting: true, pristine: true, values: true}}

        >
            {({handleSubmit, submitting, submitFailed, values, active}) => {
                const questionsHiding: { [key: string]: boolean } = {};

                rules.forEach((rule) => {
                    if (jsonLogic.apply(rule.check, values)) {
                        rule.hide.forEach((toHide) => {
                            questionsHiding[toHide] = true;
                        });
                    }
                });

                const renderedQuestions: { element: JSX.Element, id: string }[] = [];

                questionsDisplay
                    .filter(({name}) => !questionsHiding[name])
                    .forEach((question) => {
                        renderedQuestions[question.order] =
                            {
                                id: question.name,
                                element:
                                    <QuestionField
                                        numeric={numericQuestionNames.find((name) => question.name === name) !== undefined}
                                        isDerived={uneditable[question.name]}
                                        instanceId={questionnaire.instanceId}
                                        question={question}
                                    />
                            }
                    });


                tableQuestions.filter(({name}) => !questionsHiding[name]).forEach((tableQuestion) => {
                    renderedQuestions[tableQuestion.order] =
                        {
                            id: tableQuestion.name,
                            element: <FieldArray
                                // initialValue={[{}]}
                                name={tableQuestion.name}
                                tableQuestion={tableQuestion}
                                instanceId={questionnaire.instanceId}
                                derivedQuestions={uneditable}
                                // @ts-ignore
                                component={tableQuestion.inline ? Table : PaginatedTable}
                            />
                        }
                });

                //TODO should numberTables be able to be hidden?
                numberTables.filter(({name}) => !questionsHiding[name]).forEach((numberTable) => {
                    renderedQuestions[numberTable.order] = {
                        id: numberTable.name,
                        element: <NumberTable table={numberTable}/>
                    }
                });

                submit = handleSubmit;

                let notesContent = null;
                if (notes) {


                    notesContent = <p className="lead">
                        <TextDisplay text={notes}/>
                    </p>
                }

                // TODO as separate component also load questions that have not been completed
                const incompleteSections = sections
                    .map((section, index) => {
                        if (!section.isValid) {
                            return (
                                <div><Link to={generateLink(index)}>{section.name}</Link></div>
                            )
                        }
                        return null;
                    });


                let incompleteContent = null;
                if (incompleteSections.length > 0) {
                    incompleteContent = <div>
                        <h3>Incomplete Sections</h3>
                        {incompleteSections}
                    </div>
                }

                return (
                    <form id={formId} onSubmit={handleSubmit}>
                        <AutoSave delay={2000} noAutoSave={noAutoSave}/>
                        {notesContent}

                        <AnimatePresence initial={false}>
                            {renderedQuestions.filter((item) => !!item).map(({element, id}) => (
                                <motion.div
                                    // transition={{
                                    //     y: { type: "spring", stiffness: 300, damping: 30 },
                                    // }}
                                    style={{
                                        overflow: "hidden"
                                    }}
                                    key={id}
                                    initial={{
                                        opacity: 0,
                                        height: 0

                                    }}
                                    transition={{type: "spring", stiffness: 300, damping: 30}}

                                    animate={{
                                        opacity: 1,
                                        height: "auto"
                                        // , maxHeight: 10000
                                    }}
                                    exit={{
                                        opacity: 0,
                                        height: 0
                                    }}
                                >
                                    {element}
                                </motion.div>
                            ))}
                        </AnimatePresence>

                        {section.isFinal ? incompleteContent : null}

                        <button type="submit" style={{display: 'none'}} aria-hidden="true"/>

                        <SectionFormButtons
                            isFinal={section.isFinal}
                            disabled={submitting || isFinalizing}
                            onPreviousClick={() => {
                                setNoAutoSave(true);
                                onPrevious && onPrevious();
                            }}
                            previousSection={previousSection}
                            onNextClick={() => {
                                setNoAutoSave(true)
                                onNext()
                            }}
                        />
                    </form>
                )
            }}
        </Form>
    );
};
