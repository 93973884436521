import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {getHelpData, HelpData} from "../../api/questionnaireAPI";
import {History} from "history";

interface HelpState {
    error: string | null;
    isLoading: boolean;
    helpData?: HelpData;
}

function startLoading(state: HelpState) {
    state.isLoading = true
}

function loadingFailed(state: HelpState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload
}

let helpInitialState: HelpState = {
    error: null,
    isLoading: false,
};

const helpSlice = createSlice({
    name: 'help',
    initialState: helpInitialState,
    reducers: {
        getHelpDataStart: startLoading,
        getHelpDataSuccess(state, {payload}: PayloadAction<HelpData>) {
            state.helpData = payload;
            state.isLoading = false;
            state.error = null;
        },
        getHelpDataFailure: loadingFailed
    }
});


export const {
    getHelpDataStart,
    getHelpDataSuccess,
    getHelpDataFailure,
} = helpSlice.actions;


export const fetchHelpData = (history: History): AppThunk => async (dispatch) => {
    try {
        dispatch(getHelpDataStart());
        const helpData = await getHelpData();
        dispatch(getHelpDataSuccess(helpData));
    } catch (err) {
        // logout if 401
        // redirect to login if 403

        dispatch(getHelpDataFailure(err.toString()))
    }
};

export default helpSlice.reducer;
