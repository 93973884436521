import React, {useEffect, useState} from 'react'
import {FormSpy, useForm} from 'react-final-form'
import diff from 'object-diff'
import {Subtract} from "utility-types/dist/mapped-types";

interface BaseProps {
    delay: number
    noAutoSave: boolean
}

interface InjectedProps {
    values: object,
    submitting: boolean,
    submit: () => any | undefined
}

let timeout: undefined | NodeJS.Timeout;

const AutoSave = ({values, submitting, delay, submit, noAutoSave}: InjectedProps & BaseProps) => {
    const [oldValues, setValues] = useState(values);

    // clear timeout if
    if (noAutoSave && timeout) {
        clearTimeout(timeout)
    }

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout)
        }

        if (!noAutoSave) {
            timeout = setTimeout(() => {
                const difference = diff(oldValues, values);

                let changed = Object.keys(difference);


                if (changed.length && !submitting) {
                    console.log("AutoSaving...");
                    // values have changed and not currently submitting
                    setValues(values);
                    submit();
                }
            }, delay)
        }
    }, [values]);

    return null;
};


const withInForm = <P extends InjectedProps>(Component: React.ComponentType<P>): React.ComponentType<Subtract<P, InjectedProps>> => {
    return (props) => {
        const form = useForm();


        return (
            <FormSpy subscription={{submitting: true, values: true}}>
                {fsProps => (
                    <Component
                        // @ts-ignore
                        submit={form.submit}
                        // @ts-ignore

                        values={fsProps.values}
                        // @ts-ignore

                        submitting={fsProps.submitting}
                        {...props as P}
                    />
                )}
            </FormSpy>
        );
    };
};

export default withInForm(AutoSave);
