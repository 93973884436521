import React, {useEffect} from "react";
import {QuestionnaireInstance} from "../../api/questionnaireAPI";
import {TextDisplay} from "../../components/TextDisplay";
import {useHistory} from "react-router-dom";
import {QuestionnaireHeading} from "./QuestionnaireHeading";
import {TermsButton} from "../questionnaireTerms/TermsForm";
import {QuestionnaireFiles} from "../questionnaireQuestionnaire/QuestionnaireFiles";

export interface QTProps  {
    questionnaire: QuestionnaireInstance;
    checklistLink: string;
}

export const QuestionnaireTerms = ({questionnaire, checklistLink}: QTProps) => {
    const history = useHistory();

    useEffect(() => {
        // if there is a terms PDF redirect home
        if (questionnaire.termsPdf) {
            history.replace('/')
        }
    }, [questionnaire.termsPdf, history]);

    return (
        <div className="container container-fluid">
            <QuestionnaireHeading questionnaire={questionnaire}/>

            <h3>Terms of Engagement</h3>

            <QuestionnaireFiles questionnaire={questionnaire}/>


            <div className="card my-3">
                <div className="card-body">
                    <p className="lead">
                        We require sign-off from you of the Terms of Compilation Engagement given below.
                    </p>

                    {
                        !questionnaire.allowElectronicSignature ?
                        <p>
                            Please print, sign and post back these Terms.
                        </p>
                            : null
                    }

                    <p>
                        <em>If you have any questions or concerns around the Terms, please get in touch with us.</em>
                    </p>
                </div>
            </div>

            <TextDisplay text={questionnaire.terms}/>

            <div className="mb-3 mt-5 clearfix">
                <div className="float-left">
                    <div className="btn-group">

                        <TermsButton onSuccess={() => history.push(checklistLink)} questionnaire={questionnaire}/>

                    </div>
                </div>

            </div>
        </div>
    )
};
