import React from "react";
import {Question, StockTable, StockTableSection} from "../../api/questionnaireAPI";
import {TextDisplay} from "../../components/TextDisplay";
import {Field} from "react-final-form";
import {TableCard} from "./TableCard";
import {brToSpace} from "../../utils/html";

interface TFProps {
    question: Question
}

const TotalField = ({question}: TFProps) => (
    <Field step="any" type="number" component="input" tabIndex={-1} className="form-control" name={question.name} readOnly/>
);

interface NTSProps {
    section: StockTableSection,
}

const NumberTableSection = ({section}: NTSProps) => {
    const {questions, header} = section;

    return (
        <TableCard header={header}>
            {questions.map((question) => {
                let field = null;

                //TODO should be flag against question instead of this
                //  and use type from question
                if (question.questionText === 'TOTAL') {
                    field = <TotalField question={question}/>
                } else {
                    field =
                        <Field step="any" min={0} type="number" component="input" className="form-control"
                               name={question.name}/>
                }

                return (
                    //TODO htmlFor on label
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                            <TextDisplay text={brToSpace(question.questionText)}/>
                        </label>

                        <div className="col-sm-8">
                            {field}
                        </div>
                    </div>
                )
            })}
        </TableCard>
    )
}

export interface NTProps {
    table: StockTable
}

export const NumberTable = ({table}: NTProps) => {
    const {title, sections} = table;

    return (
        <div>
            <h4>{title}</h4>
            {sections.map((section) =>
                <NumberTableSection
                    section={section}
                />
            )}
        </div>
    )
};
