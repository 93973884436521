import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {deleteQuestionnaireDocument, getDocuments, postFiles, QuestionnaireDocument} from "../../api/questionnaireAPI";

interface QuestionnaireState {
    documents: QuestionnaireDocument[];
    error: string | null;
    isLoading: boolean;
    isUploading: boolean;
    progress?: number;
}

let questionnaireDisplayInitialState: QuestionnaireState = {
    // files already added
    documents: [],
    // files added by user - to be uploaded
    // errors on attempt to add files
    isLoading: false,
    isUploading: false,
    error: null,
};

function startLoading(state: QuestionnaireState) {
    state.isLoading = true
}

function loadingFailed(state: QuestionnaireState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload
}

const filesSlice = createSlice({
    name: 'documents',
    initialState: questionnaireDisplayInitialState,
    reducers: {
        getDocumentsStart: startLoading,
        getDocumentsSuccess(state, {payload}: PayloadAction<QuestionnaireDocument[]>) {
            state.documents = payload;
            state.isLoading = false;
            state.error = null;
        },
        addDocuments(state, {payload}: PayloadAction<QuestionnaireDocument[]>) {
            state.documents = [
                ...state.documents, ...payload
            ]
        },

        uploadStart(state) {
            state.isUploading = true;
            state.progress = 0;
        },
        uploadEnd(state) {
            state.isUploading = false;
            state.progress = undefined;
        },
        onProgress(state, {payload: number}) {
            state.progress = number;
        },
        removeDocument(state, {payload}: PayloadAction<QuestionnaireDocument>) {
            const index = state.documents.findIndex((document) => document.id === payload.id);

            if (index !== -1) {
                state.documents = [
                    ...state.documents.slice(0, index),
                    ...state.documents.slice(index + 1)
                ]
            }
        },
        getDocumentsFailure: loadingFailed
    }
});


export const {
    getDocumentsStart,
    getDocumentsSuccess,
    addDocuments,
    removeDocument,
    getDocumentsFailure,
    onProgress,
    uploadStart,
    uploadEnd
} = filesSlice.actions;


export const deleteDocument = (
    questionnaireId: number,
    document: QuestionnaireDocument
): AppThunk => async (dispatch, getState) => {
    try {
        await deleteQuestionnaireDocument(questionnaireId, document);
        dispatch(removeDocument(document))
    } catch (e) {

    }
}

export const fetchDocuments = (
    questionnaireId: number
): AppThunk => async (dispatch) => {
    try {
        dispatch(getDocumentsStart());
        const documents = await getDocuments(questionnaireId);

        dispatch(getDocumentsSuccess(documents));
    } catch (err) {
        // logout if 401
        if (err.response && err.response.status === 403) {
            // dispatch(logout());
        } else {

            dispatch(getDocumentsFailure(err.toString()))
        }
    }
};

export const postDocuments = (
    questionnaireId: number,
    files: File[],
    onSuccess?: () => any
): AppThunk => async (dispatch) => {
    try {
        dispatch(uploadStart());

        const documents = await postFiles(files, questionnaireId, (progress) => {
            dispatch(onProgress(progress))
        });

        dispatch(uploadEnd());
        dispatch(getDocumentsSuccess(documents));

        onSuccess && onSuccess();
    } catch (err) {

        dispatch(uploadEnd());

        // logout if 401
        if (err.response && err.response.status === 403) {
            // dispatch(logout());
        } else {

            dispatch(getDocumentsFailure(err.toString()))
        }
    }
};

export default filesSlice.reducer;
