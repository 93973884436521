import React from "react";

interface TextDisplayProps {
    text: string
}

export const TextDisplay = ({text}: TextDisplayProps) => {
    if (!text || text.length === 0) {
        return null;
    }

    // TODO not optimal - text for questions etc stored as html
    return <div dangerouslySetInnerHTML={{
    __html: text
}
}/>
    //
    // let innerNotes = null;
    // if (Array.isArray(text)) {
    //     innerNotes = text.map((note, index, array) => (
    //         <React.Fragment>
    //             <span>{note}</span>
    //             {index !== array.length - 1 && array.length !== 1 ? <br/> : null}
    //         </React.Fragment>
    //     ));
    // } else {
    //     innerNotes = text;
    // }
    //
    // return (
    //     <React.Fragment>
    //         {innerNotes}
    //     </React.Fragment>
    // );
};
