import React, {useEffect} from "react";
import {Field, Form} from "react-final-form";
import {LoginDetails, postLogin, SelectConfig} from "../../api/loginApi";
import {HeadingContainer} from "../../components/HeadingContainer";
import {Link, match, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {checkLoggedIn} from "./loginSlice";

interface LoginFormProps {
    onLoginSubmit: (loginDetails: LoginDetails) => any;
    select?: SelectConfig;
}

// @ts-ignore*
export const renderField = ({id, input, label, disabled, type, meta: {touched, submitError, submitFailed}}) => {
    const className = submitFailed && submitError ? "form-control is-invalid" : "form-control";

    return (
        <div className="form-group">
            <label>{label}</label>
            <input disabled={disabled} className={className} {...input} placeholder={label}/>
            <div className="invalid-feedback">
                {submitError}
            </div>
        </div>
    )
}

export const LoginForm = (props: LoginFormProps) => {
    const {select, onLoginSubmit} = props;

    const { search } = useLocation();

    const match: match<{ token: string }> = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const token = match.params.token;


    const parameters = new URLSearchParams(search);

    let username = parameters.get('username');
    console.log(username)

    useEffect(() => {
        if (token) {

            postLogin(token).then((e) => {
                history.push('/');
            })
            //     TODO handle error
        }
    }, [token, history, dispatch])


    return (
        <div className="container">
            <HeadingContainer>
                <h2>Login</h2>
            </HeadingContainer>
            <Form
                initialValues={{username: username || ""}}
                onSubmit={onLoginSubmit}
                render={({handleSubmit, submitting, pristine, invalid, values, errors}) => (

                    <form onSubmit={handleSubmit}>


                        <Field
                            disabled={!!select}
                            label="User Name"
                            id="username-input2"
                            name="username"
                            type="text"
                            placeholder="User Name"
                            component={renderField}
                        />

                        <Field
                            disabled={!!select}
                            label="Password"
                            id="password-input"
                            name="password"
                            component={renderField}
                            type="password"
                            placeholder="Password"
                            className="form-control"
                        />

                        <div>
                            <button type="submit" className="btn btn-primary" disabled={submitting}>
                                Login
                            </button>
                        </div>


                        {/*TODO should to be provided as a prop? the number of components responsible for routing should be kept minimal*/}
                        <Link to="/forgot-password">Forgot password</Link>
                    </form>
                )}
            />
        </div>
    )
}
