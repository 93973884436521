import {Field, Form} from "react-final-form";
import React from "react";
import {renderField} from "../login/Login";
import {postPasswordResetChangePassword, ResetPasswordData} from "../../api/loginApi";
import {Error} from "../../app/App";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import { getResetPasswordDataFailure } from "./resetPasswordSlice";

interface RPFProps {
    resetPasswordData: ResetPasswordData;
    token: string;
}

export const ResetPasswordForm = ({resetPasswordData, token}: RPFProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onResetPassword = async (resetPasswordData: object) => {
        let errors: { [key: string]: string } = {};

        await postPasswordResetChangePassword(resetPasswordData, token).then(() => {
            history.push('/login');
        }).catch(e => {
            if (e.response) {
                const data = e.response.data;

                if (e.response.status === 422) {
                    if (e.response) {

                        const data: Error[] = e.response.data;

                        data.forEach((error) => {
                            errors[error.field] = error.message;
                        })
                    }
                } else {
                    dispatch(getResetPasswordDataFailure(e.response.message));
                }
            }
        });

        return errors;
    };

    return (
        <Form
            initialValues={{username: resetPasswordData.userid}}
            onSubmit={onResetPassword}
            render={({handleSubmit, submitting, pristine}) => (

                <form onSubmit={handleSubmit}>
                    <label>
                        {resetPasswordData.fullName}
                    </label>

                    <div className="card my-3">
                        <div className="card-body">
                            <p className="card-text">
                                Enter new password
                            </p>
                        </div>
                    </div>


                    <Field
                        disabled
                        label="User Name"
                        id="username-input"
                        name="username"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />

                    <Field
                        label="New Password"
                        id="new-password-input"
                        name="password"
                        component={renderField}
                        type="password"
                        placeholder="New Password"
                        className="form-control"
                    />
                    <Field
                        label="Confirm New Password"
                        id="confirm-new-password-input"
                        name="confirmNewPassword"
                        component={renderField}
                        type="password"
                        placeholder="Confirm New Password"
                        className="form-control"
                    />

                    <div>
                        <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
                            Reset Password
                        </button>
                    </div>
                </form>
            )}
        />
    );
}
