import {HeadingContainer} from "../../components/HeadingContainer";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchResetPasswordData} from "./resetPasswordSlice";
import {match, useHistory, useRouteMatch} from "react-router-dom";
import {RootState} from "../../app/rootReducer";
import {MainLoader} from "../../components/LoadingIndicators";
import {ResetPasswordData} from "../../api/loginApi";
import {ResetPasswordForm} from "./ResetPasswordForm";

interface RPCProps {
    isLoading: boolean;
    hasError: boolean;
    resetPasswordData?: ResetPasswordData;
    token: string;
}

const ResetPasswordContent = ({hasError, resetPasswordData, isLoading, token}: RPCProps) => {
    if (isLoading) {
        return <MainLoader/>
    }

    if (hasError || !resetPasswordData) {
        return (
            <div className="card my-3">
                <div className="card-body">
                    <p className="card-text">
                        Invalid password reset token.
                    </p>
                </div>
            </div>
        )
    }

    return (
        <ResetPasswordForm token={token} resetPasswordData={resetPasswordData}/>
    );
}

export const ResetPassword = () => {
    const {
        error,
        isLoading,
        resetPasswordData
    } = useSelector((state: RootState) => state.reset);

    const match: match<{ token: string }> = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const token = match.params.token;

    useEffect(() => {
        dispatch(fetchResetPasswordData(token, history))
    }, [token, history, dispatch])

    return (
        <div className="container">

            <HeadingContainer>
                <h2>Reset Password</h2>
            </HeadingContainer>

            <ResetPasswordContent
                token={token}
                isLoading={isLoading}
                hasError={!!error}
                resetPasswordData={resetPasswordData}
            />
        </div>
    )
}
