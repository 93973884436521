import React from "react";
import {Select} from "./loginSlice";
import {FormGroup} from "react-bootstrap";
import {LoginDetails} from "../../api/loginApi";
import {Field, Form} from "react-final-form";
import {RootState} from "../../app/rootReducer";
import {TypedUseSelectorHook, useSelector} from "react-redux";

interface LRSProps {
    onLoginSubmit: (loginDetails: LoginDetails) => any;
}

export const LoginRoleSelect = (props: LRSProps) => {
    const {onLoginSubmit} = props;

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    const {select} = useTypedSelector((state: RootState) => state.login);

    const {login, selectConfig} = select!;

    return (
        <div className="container">
            <h2>Login</h2>

            <Form
                initialValues={{...login, [selectConfig.name]: selectConfig.options[0].id}}
                onSubmit={onLoginSubmit}
                render={({handleSubmit, submitting, pristine}) => (

                    <form onSubmit={handleSubmit}>
                        <div>
                            <FormGroup>
                                <div>
                                    <label>{selectConfig.title}</label>
                                    <div>
                                        <Field
                                            className="form-control"

                                            name={selectConfig.name}
                                            component="select">
                                            {
                                                selectConfig.options.map((role) => {
                                                    return (
                                                        <option value={role.id}>{role.name}</option>
                                                    )
                                                })
                                            }
                                        </Field>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>

                        <div>
                            <button type="submit" className="btn btn-primary" disabled={submitting}>
                                Login
                            </button>
                        </div>
                    </form>
                )}
            />
        </div>
    );
};
