import React, {useEffect} from "react";
import {QuestionnaireInstance} from "../../api/questionnaireAPI";
import {useDispatch, useSelector} from "react-redux";
import {fetchQuestionnaires} from "./questionnaireListSlice";
import {HeadingContainer} from "../../components/HeadingContainer";
import {Cross, Tick} from "../../components/StatusIndicators";
import {Link, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {MainLoader} from "../../components/LoadingIndicators";
import {RootState} from "../../app/rootReducer";
import {QuestionnaireTable} from "./QuestionnaireTable";
import {Alert} from "react-bootstrap";
import {clearFlash} from "../login/loginSlice";

interface QSProps {
    questionnaires: QuestionnaireInstance[]
}

interface QVSProps {

}

const QuestionnaireViewSelect = (props: QVSProps) => {
    return (
        <div className="btn-group btn-group-toggle">

            <label className="btn btn-primary active">
                <input type="radio" checked/>
                Current
            </label>

            <label className="btn btn-primary">
                <input type="radio"/>
                All
            </label>
        </div>
    )
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}



export const QuestionnaireSelector = ({questionnaires}: QSProps) => {
    const query = useQuery();
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const {isLoading} = useSelector((state: RootState) => state.questionnaireList);


    const url = match.url.endsWith('/') ? match.url.slice(0, match.url.length - 1) : match.url;
    // only show all if explicitly set to all - if no show param then current
    const all = query.get('show') === 'all';

    useEffect(() => {
        dispatch(fetchQuestionnaires(all));
        // Since we may have the issue already, ensure we're scrolled to the top
        // window.scrollTo({ top: 0 })
    }, [dispatch, all]);

    let content = null;
    if (isLoading) {
        // TODO should padding be added to mainloader component
        content = <MainLoader/>
    } else {
        content = <QuestionnaireTable questionnaires={questionnaires} url={url}/>
    }

    return (
        <div className="container">
            <HeadingContainer>
                <h2>Questionnaires</h2>
            </HeadingContainer>

            <div className="card my-3">
                <div className="card-body">
                    <p className="card-text lead">
                        Completing your Questionnaires is a three-step process.
                    </p>

                    <p className="card-text">

                        We need you to fill in the <strong>Questionnaire</strong>, sign the <strong>Terms of
                        Compilation
                        Engagement</strong> and
                        print the <strong>Checklist</strong> by clicking on the links below.

                        <br/>
                        You can only print the Checklist and sign the Terms AFTER you have filled in the
                        Questionnaire.

                    </p>

                    <p className="card-text">
                        A <Tick/> indicates you have completed the step.
                        <br/>
                        A <span className="text-danger"><Cross/></span> indicates you have yet to finish the step.
                    </p>

                    <p className="card-text">
                        When you have completed all three steps, the Questionnaire will be moved from your&nbsp;
                        <strong>Current Questionnaires</strong> list to your <strong>All Questionnaires</strong>&nbsp;
                        list (see links below).
                    </p>


                    <p className="card-text">
                        Don't forget to also post to us any paperwork along with your printed checklist.
                    </p>
                </div>
            </div>

            <ul className="nav nav-pills my-3">
                <li className="nav-item">
                    <Link
                        to={`${match.url}?show=current`}
                        className={`nav-link${all ? '' : ' active'}`}

                    >
                        Current
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        to={`${match.url}?show=all`}
                        className={`nav-link${all ? ' active' : ''}`}
                    >
                        All
                    </Link>
                </li>
            </ul>

            <div className="my-3">
                {content}
            </div>
        </div>
    )
};


