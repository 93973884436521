import baseAxios, {AxiosInstance} from "axios";

const axiosInstance: AxiosInstance = baseAxios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // logout on 403
    if (error.response && error.response.status === 403) {
        window.location.href = '/login';
        return;
    }

    return Promise.reject(error);
});

export default axiosInstance;
