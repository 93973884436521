import React from "react";
import {brToSpace} from "../../utils/html";

interface TCProps {
    header: string,
}

export const TableCard: React.FC<TCProps> = ({header, children}) => {
    const headerText = brToSpace(header);

    return (
        <div className="card my-3 question-input">
            <h5 className="card-header">
                {headerText}
            </h5>

            <div className="card-body">
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
};
