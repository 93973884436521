import axios from "../utils/axios";
import {isString} from "util";

interface BaseLoginDetails {
    username: string;
    password: string;
}

export interface LoginDetails extends BaseLoginDetails {
    roleId?: number;
}

export interface TokenLogin {
    token: string
}

export interface ChangePasswordDetails extends BaseLoginDetails {
    newPassword: string;
    confirmNewPassword: string;
}


export interface Token {
    iss: string;
    aud: string;
    iat: number;
    nbf: number;
    jti: number;
    id: number;
    username: string;
    token: string;
}

export interface UserData {
    token: Token;
    logo?: string;
    imageType?: string;
    accountingFirmId: number;
}

interface SelectOption {
    id: number;
    name: string;
}

export interface SelectConfig {
    name: string;
    title: string;
    options: SelectOption[];
}

export interface PasswordRule {
    digits?: number;
    length?: number;
    specialchars?: number;
}


export interface PasswordResetRequestDetails {
    email: string
}

export interface ResetPasswordData {
    fullName: string;
    userid: string;
    email: string;

}

export async function getResetPasswordData(token: string) {
    const {data} = await axios.get<ResetPasswordData>(`site/reset-password-data?token=${token}`);
    return data;
}

export async function postPasswordResetRequest(passwordResetRequestDetails: PasswordResetRequestDetails) {
    let url = `site/request-password-reset`;

    return axios.post(url, passwordResetRequestDetails);

    //
    // const decodedToken: Token = jwt_decode(data);
    // decodedToken.token = data;
    //
    // console.log(decodedToken);
    //
    // return decodedToken;
}



export async function postLogin(loginDetails: LoginDetails|string) {

    let url = `site/login`;

    if (typeof  loginDetails === 'string') {
        url  = `${url}?token=${loginDetails}`
    }

    return axios.post(url, loginDetails);

    //
    // const decodedToken: Token = jwt_decode(data);
    // decodedToken.token = data;
    //
    // console.log(decodedToken);
    //
    // return decodedToken;
}


export async function postChangePassword(loginDetails: ChangePasswordDetails) {
    let url = `site/change-password`;

    return axios.post(url, loginDetails);

    //
    // const decodedToken: Token = jwt_decode(data);
    // decodedToken.token = data;
    //
    // console.log(decodedToken);
    //
    // return decodedToken;
}

export async function postPasswordResetChangePassword(passwordResetData: object, token: string) {
    let url = `site/password-reset?token=${token}`;

    return axios.post(url, passwordResetData);
}

export async function optionsCheckLoggedIn() {
    let url = `site/index`;

    const {data} = await axios.get(url);
    return data
}


export async function getPasswordRule() {
    let url = `site/password-rule`;

    const {data} = await axios.get<string[]>(url);
    return data
}

export async function postLogout() {
    let url = `site/logout`;

    return axios.post(url, {});
}
