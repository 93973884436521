import {HeadingContainer} from "../../components/HeadingContainer";
import {Field, Form} from "react-final-form";
import React from "react";
import {renderField} from "./Login";
import {PasswordResetRequestDetails} from "../../api/loginApi";

interface FPProps {
    onForgotPasswordSubmit: (passwordResetRequestDetails: PasswordResetRequestDetails) => any
}

export const ForgotPasswordForm = (props: FPProps) => {
    const {onForgotPasswordSubmit} = props;

    return (
        <div className="container">
            {/*TODO as component for change password, forgot password, login?*/}
            {/*<div style={{maxWidth: 330}}>*/}

                <HeadingContainer>
                    <h2>Forgot Password</h2>
                </HeadingContainer>

                <div className="card my-3">
                    <div className="card-body">
                        <p className="card-text">
                            Enter your email address below to receive a new password via email.
                        </p>
                    </div>
                </div>

                <Form
                    onSubmit={onForgotPasswordSubmit}
                    render={({handleSubmit, submitting, pristine, invalid, values, errors}) => (

                        <form onSubmit={handleSubmit}>
                            <Field
                                label="Email Address"
                                id="email-input"
                                name="email"
                                type="email"
                                placeholder="Enter email"
                                component={renderField}
                            />

                            <div>
                                <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
                                    Request Reset
                                </button>
                            </div>
                        </form>
                    )}
                />
            {/*</div>*/}
        </div>
    )
}
