import React from "react";

const style = {verticalAlign: "unset", height: '1em', width: '1em'};
export const Cross = () => (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} width="18" height="18" viewBox="0 0 18 18" fill="none" stroke="currentColor"
         strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers">
        <line x1="3" y1="15" x2="15" y2="3"/>
        <line x1="3" y1="3" x2="15" y2="15"/>
    </svg>
);

export const Tick = () => (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} width="18" height="18" viewBox="0 0 18 18" fill="none" stroke="currentColor"
         strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather feather-layers">
        <polyline points="3 12 6 15 15 3"/>
    </svg>
);