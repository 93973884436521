import React from 'react';
// import './App.css';
import {useDispatch} from "react-redux";
import beancounterLogo from "../defaultlogo.gif";
import {LoginForm} from "../features/login/Login";
import {
    LoginDetails,
    PasswordResetRequestDetails,
    postLogin,
    postPasswordResetRequest,
    TokenLogin
} from "../api/loginApi";
import {logout, Select, setFlash, setSelect} from "../features/login/loginSlice";
import {LoginRoleSelect} from "../features/login/LoginRoleSelect";
import {Redirect, Route, Switch, useHistory, withRouter} from 'react-router-dom';
import {Questionnaire} from "../features/questionnaireQuestionnaire/Questionnaire";
import {PasswordChangeForm} from "../features/login/PasswordChangeForm";
import {Button, Nav, Navbar} from "react-bootstrap";
import {ForgotPasswordForm} from "../features/login/ForgotPasswordForm";
import {Help} from "../features/help/Help";
import {ResetPassword} from "../features/resetPassword/ResetPassword";

export interface Error {
    field: string;
    message: string;
}

function extractValidationErrors(response: any) {
    const errors: { [key: string]: string } = {};

    if (response.status === 422) {

            const data: Error[] = response.data;

            data.forEach((error) => {
                errors[error.field] = error.message;
            })
    }
    return errors;
}



// TODO routing, user state save
const App: React.FunctionComponent = () => {
    const dispatch = useDispatch();


    const history = useHistory();

    // setInterval(() => dispatch(checkLoggedIn(history)), 5000);

    const onLoginSubmit = async (loginDetails: LoginDetails) => {
        let errors: { [key: string]: string } = {};

        await postLogin(loginDetails).then((e) => {
            history.push('/');
        }).catch(e => {
            const response = e.response;
            if (response) {
                const data = response.data;
                if (response.status === 300) {

                    console.log(data.redirect);
                    if (data.redirect) {
                        window.location.href = data.redirect;
                    }

                    const select: Select = {
                        login: loginDetails,
                        selectConfig: data
                    };

                    dispatch(setSelect(select));
                    history.push('/login-role');

                }
                errors = extractValidationErrors(response);
                //    TODO other errors
            }
        });

        return errors;
    };

    const onPasswordResetRequest = async (passwordResetRequestDetails: PasswordResetRequestDetails) => {
        let errors: { [key: string]: string } = {};

        // actionRequestPasswordReset
        await postPasswordResetRequest(passwordResetRequestDetails).then((e) => {
            dispatch(setFlash(`Password reset requested for ${passwordResetRequestDetails.email}`))
            history.push("/login");
        }).catch((e) => {
            const response = e.response;
            if (response) {
                errors = extractValidationErrors(response);
                //    TODO other errors
            }
        });

        return errors;
    }

    return (
        <div className="App">
            <div className="border-bottom sticky-top">
                <Navbar bg="white" expand="sm">
                    <Switch>
                        <Route path={["/login", "/forgot-password", "/reset-password", "/login-role"]}>
                            <Navbar.Brand>
                                <img
                                    style={{
                                        maxWidth: "250px",
                                        maxHeight: "50px",
                                        height: "auto"
                                    }}
                                    className="site-logo"
                                    src={beancounterLogo}
                                    alt="24/7 Beancounter"/>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        </Route>

                        <Route path="/">
                            <Navbar.Brand href="/">
                                <img
                                    style={{
                                        maxWidth: "250px",
                                        maxHeight: "50px",
                                        height: "auto"
                                    }}
                                    className="site-logo"
                                    onError={(e) => {
                                        // @ts-ignore
                                        e.target.src = beancounterLogo;
                                    }}
                                    src={`${process.env.REACT_APP_BASE_URL}/site/logo`}
                                    alt="24/7 Beancounter"/>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <React.Fragment>
                                    <Nav className="ml-auto">
                                        <Nav.Link href="/change-password">Change Password</Nav.Link>
                                        <Nav.Link href="/help">Help</Nav.Link>
                                    </Nav>
                                    <Button type="submit" variant="outline-success"
                                            onClick={() => dispatch(logout(history))}>Logout</Button>
                                </React.Fragment>
                            </Navbar.Collapse>
                        </Route>
                    </Switch>
                </Navbar>
            </div>

            <div className='wrap'>
                <Switch>
                    <Route path="/login.php*">
                        <LoginForm onLoginSubmit={onLoginSubmit}/>
                    </Route>

                    <Route path="/login/:token?">
                        <LoginForm onLoginSubmit={onLoginSubmit}/>
                    </Route>

                    <Route path="/forgot-password">
                        <ForgotPasswordForm onForgotPasswordSubmit={onPasswordResetRequest}/>
                    </Route>

                    <Route path="/reset-password/:token">
                        <ResetPassword/>
                    </Route>

                    {/* TODO should only be allowed when select is present or merge with login */}
                    <Route path="/login-role">
                        <LoginRoleSelect onLoginSubmit={onLoginSubmit}/>
                    </Route>

                    <Route path="/change-password">
                        <PasswordChangeForm onSuccess={() => {
                            dispatch(setFlash('Password changed'));
                            history.push('/');
                        }}/>
                    </Route>

                    <Route path="/questionnaires">
                        <Questionnaire/>
                    </Route>

                    <Route path="/help">
                        <Help/>
                    </Route>

                    <Route path="/">
                        <Redirect to="/questionnaires"/>
                    </Route>
                </Switch>
            </div>

            {/*<header className="App-header">*/}
            {/*<img src={logo} className="App-logo" alt="logo" />*/}
            {/*</header>*/}
        </div>
    );
};

export default withRouter(App);
