import React, {useState} from "react";
import {brToSpace} from "../../utils/html";
import {Field} from "react-final-form";
import {generateFieldName} from "./QuestionField";
import {TableQuestion} from "../../api/questionnaireAPI";
import Decimal from "decimal.js";

export interface TableProps {
    tableQuestion: TableQuestion;
    instanceId: number;
}

export const Table = (props: TableProps) => {
    // @ts-ignore
    const {fields, meta: {error, submitFailed}, tableQuestion, derivedQuestions, instanceId} = props;

    const [checked, setChecked] = useState<{ [key: string]: boolean }>({});

    const values: { [key: string]: string }[] = fields.value;
    let checkedFields: object[] = [];

    if (values) {
        checkedFields = values.filter((field, i) => {
            return checked[i];
        });

    }

    const hasChecked = checkedFields.length !== 0;
    const allChecked = checkedFields.length === fields.length;
    const innerQuestions = tableQuestion.questions;
    const numFields = fields.length;
    const hasTotaled = innerQuestions.map(({totaled}) => totaled).reduce((prev, curr) => prev || curr, false);

    const numCols = innerQuestions.length + 1;
    const emptyRow = <tr>
        <td colSpan={numCols}>
            {tableQuestion.title} empty
        </td>
    </tr>

    return (
        <div className="card my-3">
            <h5 className="card-header">
                {brToSpace(tableQuestion.title)}
            </h5>


            <div className="bg-white border">
                <div className="card-body">

                    <div className="btn-group">
                        <button className="btn btn-primary" onClick={() => {
                            fields.push({})
                            // uncheck all on add
                            setChecked({})
                        }}>Add
                        </button>
                        <button className="btn btn-danger" disabled={!hasChecked} onClick={() => {

                            for (let i = numFields - 1; i >= 0; i--) {
                                if (checked[i]) {
                                    fields.remove(i);
                                }
                            }
                            // uncheck all on delete
                            setChecked({})
                        }}>Delete
                        </button>
                    </div>

                </div>


                <div>
                    <table className="table table-responsive-md mb-0">
                        <thead>
                        <tr>
                            <th>
                                <input
                                    type="checkbox"
                                    disabled={numFields === 0}

                                    onClick={() => {
                                        const toCheck: { [key: number]: boolean } = {};

                                        for (let i = 0; i < numFields; i++) {
                                            // if all checked set false otherwise set all to true/checked
                                            toCheck[i] = !allChecked;
                                        }

                                        setChecked(toCheck)
                                    }}

                                    checked={allChecked && hasChecked}
                                />
                            </th>


                            {innerQuestions.map((innerQuestion) =>
                                <th scope="col">
                                    {brToSpace(innerQuestion.questionText)}
                                </th>
                            )}
                        </tr>
                        </thead>
                        <tbody>

                        {fields.length === 0 ? null :
                            fields.map((field: string, index: number) => {
                                const fieldChecked = checked[index];

                                return (
                                    <tr className={fieldChecked ? 'table-active' : undefined}>
                                        <td>
                                            <input

                                                type="checkbox"
                                                onClick={() => {
                                                    setChecked({...checked, [index]: !checked[index],})
                                                }}
                                                checked={checked[index]}
                                            />
                                        </td>
                                        {innerQuestions.map((question) => {
                                            let questionField;
                                            const type = question.type;
                                            const name = generateFieldName(question, field);

                                            if (type.name === 'SELECT') {
                                                const questionOptions = type.questionOptions;
                                                questionField = <Field
                                                    style={{minWidth: 75}}
                                                    component="select"
                                                    className="form-control"
                                                    name={name}
                                                >
                                                    {questionOptions.map((questionOption) => {
                                                        return (
                                                            <option value={questionOption.name}>
                                                                {questionOption.value}
                                                            </option>
                                                        )
                                                    })}
                                                </Field>
                                            } else {
                                                questionField = <Field
                                                    step={question.totaled ? "any" : null}
                                                    style={{minWidth: 75}}
                                                    type={question.totaled ? "number" : "text"}
                                                    component="input"
                                                    className="form-control"
                                                    name={name}
                                                />


                                            }
                                            return (<td>
                                                    {questionField}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                );
                            })
                        }

                        {hasTotaled ?
                            <tr className="thead-light">
                                <th/>
                                {
                                    innerQuestions.map((question) => {
                                        let content = null;

                                        if (question.totaled) {
                                            let total = 0;


                                            if (values) {
                                                total = values
                                                    .map((value) => value[question.name])
                                                    .reduce((sum, value) => sum + Number(value || 0), 0);
                                            }



                                            content = new Decimal(total).toDecimalPlaces(2).toString();
                                        }


                                        return (
                                            <th className="bold">{content}</th>
                                        )
                                    })
                                }
                            </tr>
                            : null}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
