import jsonLogic from "json-logic-js";
import {calcFarmDwellingExpenses} from "./legacyCalculations";

const percentage = (x: number|string): string => {
    let percentage = parseFloat(x.toString());

    if ( !isFinite(percentage) || isNaN(percentage)  ) {
        percentage = 0;
    }

    return `${percentage.toFixed(2)}`
};

jsonLogic.add_operation('percentage', percentage);
jsonLogic.add_operation('calcFarmDwellingExpenses', calcFarmDwellingExpenses);
jsonLogic.add_operation('isFinite', isFinite);


export default jsonLogic;
