import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {History} from "history";
import {getResetPasswordData, ResetPasswordData} from "../../api/loginApi";

interface ResetPasswordState {
    error: string | null
    isLoading: boolean;
    resetPasswordData?: ResetPasswordData
}

let resetPasswordInitialState: ResetPasswordState = {
    isLoading: false,
    error: null,
};

function startLoading(state: ResetPasswordState) {
    state.isLoading = true
}

function loadingFailed(state: ResetPasswordState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload;
}

const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState: resetPasswordInitialState,
    reducers: {
        getResetPasswordDataStart: startLoading,
        getResetPasswordDataSuccess(state, {payload}: PayloadAction<ResetPasswordData>) {
            state.resetPasswordData = payload;
            state.error = null;
            state.isLoading = false;
        },
        getResetPasswordDataFailure: loadingFailed,
    }
});

export const {
    getResetPasswordDataStart,
    getResetPasswordDataSuccess,
    getResetPasswordDataFailure
} = resetPasswordSlice.actions;


// fetch password reset data
export const fetchResetPasswordData = (
    token: string,
    history: History
): AppThunk => async (dispatch) => {

    try {
        dispatch(getResetPasswordDataStart());
        const resetPasswordData = await getResetPasswordData(token);
        dispatch(getResetPasswordDataSuccess(resetPasswordData));
    } catch (err) {
        // logout if 403
        // if (err.response && err.response.status === 403) {
        //     forbiddenHandler(history);
        // } else {
        dispatch(getResetPasswordDataFailure(err.toString()))
        // }
    }
};

export default resetPasswordSlice.reducer;
