import React, {useEffect} from "react";
import {
    Question,
    QuestionnaireInstance,
    saveQuestionnaireSection,
    Section,
    SectionAnswers,
    TableQuestion
} from "../../api/questionnaireAPI";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/rootReducer";
import {fetchQuestions, setGoHome} from "./questionnaireSectionSlice";
import {MainLoader} from "../../components/LoadingIndicators";
import {SectionForm} from "./SectionForm";
import store from "../../app/store";
import {Cross} from "../../components/StatusIndicators";
import {useHistory} from "react-router";
import {SidebarLoader} from "../../components/SidebarLoader";
import {Dropdown} from "react-bootstrap";
import diff from "object-diff";

interface QSLProps {
    sections: Section[]
}

export interface QSProps {
    termsLink: string;
    formId: string
    selectedSection: number
    questionnaire: QuestionnaireInstance
    previousSection?: number,
    section: Section,
    loadingSections: boolean,
    onRedirect: (sectionIndex: string | number) => void;
    onNext: () => any;
    onPrevious?: () => any;
    generateLink: (sectionIndex: number) => string;
}

export const QuestionnaireSection = (
    {
        termsLink,
        onRedirect,
        formId,
        previousSection,
        section,
        questionnaire,
        selectedSection,
        loadingSections,
        onNext,
        onPrevious,
        generateLink
    }: QSProps
) => {
    const {
        sections
    } = useSelector((state: RootState) => state.questionnaireDisplay);

    const history = useHistory();

    const {instanceId: questionnaireId} = questionnaire;

    const {
        isLoading,
        error,
        questions,
        tableQuestions,
        numberTables,
        answers
    } = useSelector((state: RootState) => state.questionnaireSection);

    const dispatch = useDispatch();

    useEffect(() => {
        // TODO selected section should probably be handled inside section slice
        dispatch(fetchQuestions(questionnaireId, sections[selectedSection].id));
    }, [dispatch, questionnaireId, selectedSection, sections]);

    const questionsHide: { [key: string]: boolean } = {};
    // filter questions that appear in the questionsHide object
    let questionsDisplay: Question[] = questions.filter(({name}) => !questionsHide[name]),
        tableQuestionsDisplay: TableQuestion[] = tableQuestions.filter(({name}) => !questionsHide[name]);


    // TODO should be thunk
    const onSectionSubmit = (sectionAnswers: SectionAnswers) => {
        const extracted = () => {
            const {sectionLoad, goHome} = store.getState().questionnaireSection;
            if (goHome) {
                dispatch(setGoHome(false));
                history.push('/')
            }

            if (sectionLoad !== undefined) {
                onRedirect(sectionLoad)
            }
        }

        return saveQuestionnaireSection(questionnaireId, section.id, sectionAnswers).then((e) => {
            extracted();
        }).catch((e) => {
        });
    };

    // TODO only show loading when needed
    if (isLoading) {
        // show loader when loading section with no previously loaded section
        return <MainLoader/>
    }

    if (error) {
        return <div>{error}</div>
    }

    const {name} = section;

    // let resetButton = <div>
    //     <button disabled={!answersChanged} className="btn btn-secondary"
    //             onClick={() => dispatch(resetSectionQuestions())}>Reset
    //     </button>
    // </div>;


    return (
        <div>
            <h4>{name}</h4>
            {/*{resetButton}*/}


            <SectionForm
                generateLink={generateLink}
                termsLink={termsLink}
                onRedirect={() => {
                }}
                onNext={onNext}
                onPrevious={onPrevious}
                formId={formId}
                numberTables={numberTables}
                tableQuestions={tableQuestionsDisplay}
                questions={questionsDisplay}
                loadingSections={loadingSections}
                previousSection={previousSection}
                section={section}
                selectedSection={selectedSection}
                onSubmit={onSectionSubmit}
                questionnaire={questionnaire}/>
        </div>
    )
};


type BaseSectionSelectorProps = {
    finalization: boolean;
    loading: boolean;
    selectedSection: number;
    setSelectedSection: (index: number) => void

    // TODO the next / previous should be defined in component above
} & QSLProps;


interface SSTBProps extends BaseSectionSelectorProps {
    saving: boolean;
    hasPrevious: boolean;
    hasNext: boolean;
    onPrevious?: () => any;
    onNext?: () => any;
}

// @ts-ignore
const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <a
        className="nav-link"
        href=""
        // @ts-ignore
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        &#x25bc;
    </a>
));

export const SectionSelectorTopBar = (
    {
        hasPrevious,
        // TODO remove
        hasNext,
        saving,
        loading,
        sections,
        selectedSection,
        onPrevious,
        onNext,
        finalization,
        setSelectedSection
    }: SSTBProps) => {
    const section = sections[selectedSection];

    let content = null;


    if (section) {
        let sectionName;
        if (loading || saving) {
            let text = loading ? 'Loading...' : 'Saving...';

            sectionName = <div className="nav-link">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                {text}
            </div>
        } else {
            sectionName =
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} variant="secondary"
                                     id="dropdown-basic">{section.name}&nbsp;</Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            sections.map(({name}, index) => {
                                return (
                                    <Dropdown.Item as="button"
                                                   onClick={() => setSelectedSection(index)}>{name}</Dropdown.Item>
                                );
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>;
        }

        content = <React.Fragment>
            <ul className="nav nav-pills nav-fill" style={{width: '100%'}}>
                {
                    hasPrevious ?

                        <li style={{maxWidth: 50}} className="border-right nav-item">
                            <a aria-label="Previous" className="nav-link" href="#" onClick={onPrevious}>
                                <i className="fa fa-arrow-left"/>
                            </a>
                        </li>
                        : <li className="nav-item" style={{maxWidth: 50}}>
                        </li>

                }


                <li className="nav-item">
                    {sectionName}
                </li>
                <li style={{maxWidth: 50}} className="border-left nav-item">
                    <a className="nav-link" aria-label="next" href="#" tabIndex={-1} onClick={onNext}>
                        <i className="fa fa-arrow-right"/>
                    </a>
                </li>
            </ul>
        </React.Fragment>
    }

    return (
        <div className="section-bar fixed-top bg-white shadow-sm sm">
            <nav className="nav nav-underline">
                {content}
            </nav>
        </div>

    );
};

interface SSSProps extends BaseSectionSelectorProps {

    questionnaire: QuestionnaireInstance
}

export const SectionSelectorSidebar = (
    {
        questionnaire,
        finalization,
        loading,
        sections,
        setSelectedSection,
        selectedSection
    }: SSSProps) => {
    let loadingSection = null;
    if (loading) {
        loadingSection = <SidebarLoader/>
    }

    return (
        <div className="sidebar-sticky">

            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>{finalization ? 'Finalization' : 'Questionnaire Sections'}</span>
            </h6>
            <ul className="nav flex-column">

                {sections.map((section, index) => {

                    const {
                        name, id,

                        // isValid,
                    } = section;

                    const selected = selectedSection === index;
                    let statusIndicator = null;

                    const onClick = () => setSelectedSection(index);


                    let link;
                    if (selected) {
                        link =
                            <a
                                style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
                                className="nav-link disabled"
                                href="#"
                                tabIndex={-1}
                                aria-disabled="true"
                                onClick={onClick}
                            >
                                {name}
                            </a>

                    } else {
                        link = <SectionLink section={section} instanceId={questionnaire.instanceId} onClick={onClick}/>
                    }

                    return (
                        <li className="nav-item">
                            {link}
                            {/*<div onClick={() => setSelectedSection(id)}*/}
                            {/*     style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>*/}
                            {/*    {name}*/}

                            {/*</div>*/}
                        </li>
                    )
                })}
            </ul>

            <ul className="nav flex-column">
                <li className="nav-item">
                    {loadingSection}
                </li>
            </ul>
        </div>
    )
};

interface SLProps {
    section: Section,
    instanceId: number,
    onClick: () => void
}

const SectionLink = ({section, instanceId, onClick}: SLProps) => {
    let indicator = null;
    let textClass = "nav-link";
    if (!section.isValid) {
        indicator = <Cross/>;
        textClass += " text-danger";
    }

    return (
        <a style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
           className={`nav-link ${textClass}`}
           href="#"
           onClick={onClick}>
            {indicator}
            {section.name}
        </a>
    );
}
