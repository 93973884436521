import React from "react";
import {FormSpy, useForm} from "react-final-form";
import {setGoHome} from "./questionnaireSectionSlice";
import {useDispatch} from "react-redux";

interface SBProps {
    isFinal: boolean;
    previousSection?: number;
    onPreviousClick?: () => any;
    onNextClick: () => any;
    disabled: boolean;
}


export const SectionFormButtons = ({previousSection, onPreviousClick, disabled, onNextClick, isFinal}: SBProps) => {
    let previousButtons: JSX.Element | null = null;

    const form = useForm();
    const dispatch = useDispatch();

    return (
        <FormSpy>
            {({submitting}) => {
                const hasPrevious = previousSection !== undefined;
                return (
                    <div className="mb-3 mt-5">
                        {/* desktop buttons */}
                        <div className="d-none d-sm-block">
                            <div className="row">
                                <div className="col-4">

                                    {
                                        hasPrevious ?
                                            <button
                                                type="button"
                                                onClick={onPreviousClick}
                                                disabled={disabled}
                                                className="btn d-none d-sm-block btn-lg btn-success"
                                            >
                                                Previous
                                            </button> : null
                                    }
                                </div>
                                <div className="col-4">
                                    <button
                                        type="button"
                                        disabled={disabled}
                                        onClick={() => {
                                            dispatch(setGoHome(true));
                                            form.submit();
                                        }}
                                        className="btn btn-lg d-none d-sm-block btn-success m-auto"
                                    >
                                        {submitting ? <React.Fragment>
                                            <span className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"/> Saving
                                        </React.Fragment> : "Save and Exit"}
                                    </button>
                                </div>
                                <div className="col-4">
                                    <button
                                        type="button"
                                        onClick={
                                            onNextClick
                                        }
                                        disabled={disabled}
                                        className="btn btn-lg btn-success d-block ml-auto"
                                    >
                                        {isFinal ? "Finalise" : "Next"}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* mobile buttons */}
                        <div className="d-sm-none ">

                            <div className="row ">
                                <div className="col-6">

                                    {
                                        hasPrevious ?
                                            <button
                                                type="button"
                                                title="Previous"
                                                onClick={onPreviousClick}
                                                disabled={disabled}
                                                className="btn btn-lg  btn-success"
                                            >
                                                &laquo;
                                            </button> : null
                                    }
                                </div>
                                {/*<div className="col-6">*/}
                                {/*    <button*/}
                                {/*        disabled={submitting}*/}
                                {/*        onClick={form.submit}*/}
                                {/*        className="btn btn-lg btn-success m-auto"*/}
                                {/*    >*/}
                                {/*        Save and Exit*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                <div className="col-6">
                                    <div className="ml-auto">
                                        <button
                                            type="button"
                                            title="Next"
                                            onClick={
                                                onNextClick
                                            }
                                            disabled={disabled}
                                            className="btn btn-lg d-block btn-success ml-auto"
                                        >
                                            &raquo;
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}

        </FormSpy>

    );
}
