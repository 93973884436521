export const brToSpace = (stringContent: string, stripTags = true): string => {
    const element = document.createElement('div');
    element.innerHTML = stringContent;

    while (element.getElementsByTagName('br').length !== 0) {
        let brTags = element.getElementsByTagName('br');

        const replaceNode = document.createElement('span');
        replaceNode.innerText = " "

        const node = brTags[0];

        node.parentNode!.replaceChild(replaceNode, node);
    }

    const message = element.innerHTML;

    if (stripTags) {
        return extractText(message);
    }

    return message;
};

export const extractText = (stringContent: string): string => {
    const element = document.createElement('div');
    element.innerHTML = stringContent;
    return element.innerText;
}
