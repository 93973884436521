import { combineReducers } from '@reduxjs/toolkit'

import questionnaireReducer from "../features/questionnaireQuestionnaire/questionnaireSlice";
import questionnaireListReducer from "../features/questionnaireList/questionnaireListSlice";
import questionnaireDisplayReducer from "../features/questionnaireDisplay/questionnaireDisplaySlice";
import questionnaireSectionReducer from "../features/questionnaireSection/questionnaireSectionSlice";
import questionnaireChecklistReducer from "../features/questionnaireChecklist/questionnaireChecklistSlice";
import loginReducer from "../features/login/loginSlice";
import helpReducer from "../features/help/helpSlice";
import resetReducer from "../features/resetPassword/resetPasswordSlice";
import documentsReducer from "../features/files/filesSlice";
import {questionnaireSection} from "../services/questionnaire-section";


const rootReducer = combineReducers({
    questionnaire: questionnaireReducer,
    questionnaireList : questionnaireListReducer,
    questionnaireDisplay: questionnaireDisplayReducer,
    questionnaireSection: questionnaireSectionReducer,
    questionnaireChecklist: questionnaireChecklistReducer,
    login: loginReducer,
    help: helpReducer,
    reset: resetReducer,
    documents: documentsReducer,
    [questionnaireSection.reducerPath]: questionnaireSection.reducer

});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
