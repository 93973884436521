type BinaryUnits = 'B' | 'kB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB' ;
type ReadableSize = {
    unit: BinaryUnits;
    size: number
};

const binaryUnits: BinaryUnits[] = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const convertToReadableFileSize = (bytes: number): ReadableSize => {
    let curSize = Math.abs(bytes);

    if (curSize < 1024) {
        return {
            size: curSize,
            unit: 'B'
        };
    }

    let unitIndex = -1;
    while (curSize >= 1024 &&  unitIndex < binaryUnits.length - 1) {
        curSize /= 1024;
        unitIndex++;
    }

    return {
        size: curSize,
        unit: binaryUnits[unitIndex]
    };
}
