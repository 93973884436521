import React, {useState} from "react";
import {Question, QuestionQuery, saveQuestionQuery} from "../../api/questionnaireAPI";
import {Button, Modal} from "react-bootstrap";
import {Field, Form} from "react-final-form";

export interface QQBProps {
    question?: Question
    questionnaireId: number
    query?: string
}

export const QuestionQueryButton = ({question, questionnaireId, query}: QQBProps) => {
    const [show, setShow] = useState(false);
    // prevent having to reload questions by saving query here
    const [queryAdded, setQueryAdded] = useState<string | undefined>(query);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [deleteShowOverride, setDeleteShowOverride] = useState<boolean | undefined>();

    if (question && question.type.name === "LABEL") {
        return null;
    }

    const questionQuery: QuestionQuery = {
        queryText: queryAdded,
        questionnaireId: questionnaireId
    };

    let formId = `query-form`;
    if (question) {
        questionQuery.questionId = question.id;
        formId = `question-${question.id}-${formId}`;
    }

    async function onQuerySubmit(formData: QuestionQuery) {
        await saveQuestionQuery(formData).then((e) => {
            setShow(false);
            const queryText = formData.queryText;
            setQueryAdded(queryText);
            // set to current questionQuery state
            setDeleteShowOverride(!!questionQuery.queryText);
        }).catch(e => {
            //    TODO failure handling - set error flash
        });

    }

    const deleteQuery = () => {
        setDeleting(true);

        const formData = {
            questionnaireId: questionnaireId,
        };

        if (question) {
            // @ts-ignore
            formData.questionId = question.id;
        }

        onQuerySubmit(formData).then(() => {
            setDeleting(false);
        });
    }

    let showDelete = !!questionQuery.queryText;
    if (deleteShowOverride !== undefined) {
        showDelete = deleteShowOverride;
    }

    return (
        <Form
            onSubmit={onQuerySubmit}
            initialValues={questionQuery}
            render={({handleSubmit, submitting, form, pristine, invalid, values, errors}) => {
                const handleClose = () => {
                    //  close modal
                    setShow(false);
                };

                return (
                    <React.Fragment>
                        {/* on exit reset form */}
                        <Modal
                            onExited={() => {
                                form.reset()
                                setDeleteShowOverride(undefined);
                            }
                            } show={show} onHide={handleClose}>
                            <Modal.Body>
                                <p className="card">
                                    <div className="card-body">

                                        {question ?
                                            <p className="lead card-text">
                                                Enter note for question:
                                            </p>

                                            :
                                            <p className="lead card-text">
                                                Enter Note
                                            </p>
                                        }

                                        {question ?
                                            <p className="card-text">
                                                <span dangerouslySetInnerHTML={{__html: question.questionText}}/>
                                            </p>
                                            : null}
                                    </div>
                                </p>

                                <form onSubmit={handleSubmit} id={formId}>
                                    <div className="form-group">
                                        <label>Note</label>
                                        <Field
                                            rows="5"
                                            id="query-input"
                                            name="queryText"
                                            placeholder="Enter note..."
                                            className="form-control"
                                            component="textarea"
                                        />
                                    </div>
                                </form>
                            </Modal.Body>

                            <Modal.Footer>
                                {/* only show delete button when a query already exists */}
                                {
                                    showDelete
                                        ? <Button
                                            disabled={submitting || deleting}
                                            variant="danger"
                                            onClick={deleteQuery}
                                        >
                                            Delete
                                        </Button>
                                        : null
                                }

                                {/*TODO loading indicator*/}
                                <Button variant="secondary" onClick={handleClose}>Close</Button>
                                <Button disabled={submitting || deleting} onClick={() => handleSubmit()}
                                        variant="primary">
                                    Save Note
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {
                            question ?
                            <button
                                type="button"
                                className={questionQuery.queryText ? "btn btn-sm btn-primary" : "btn btn-sm btn-outline-primary"}
                                onClick={() => setShow(true)} aria-label="Question Note">
                                <i className="fa fa-sticky-note"/>
                            </button>
                                : <button
                                    type="button"
                                    className={questionQuery.queryText ? "btn btn-primary" : "btn btn-outline-primary"}
                                    onClick={() => setShow(true)} aria-label="Questionnaire Note">
                                    Add Note
                                </button>
                        }
                    </React.Fragment>
                )
            }}
        />

    )
};
