import {Link} from "react-router-dom";
import React from "react";
import {Cross, Tick} from "../../components/StatusIndicators";
import {QuestionnaireInstance} from "../../api/questionnaireAPI";

interface QTProps {
    questionnaires: QuestionnaireInstance[];
    url: string;
}

const ChecklistLink = ({questionnaire, url}: { questionnaire: QuestionnaireInstance, url: string }) => {
    let text = <React.Fragment>Print Checklist</React.Fragment>;

    if (questionnaire.checklistPdf) {
        return (
            <a href={questionnaire.checklistPdf} target="_blank">
                {text}&nbsp;<Tick/>
            </a>
        )
    } else {
        if (questionnaire.jobcomplete) {
            return <Link
                to={`${url}/${questionnaire.instanceId}/checklist`}
            >
                {text}&nbsp;<span className="text-danger"><Cross/></span>
            </Link>
        } else {
            return text;
        }
    }
};

interface QLProps {
    questionnaire: QuestionnaireInstance
}


export const QuestionnaireTable = ({questionnaires, url}: QTProps) => {
    if (questionnaires.length === 0) {
        return (
            <p className="lead text-center"><em>No Questionnaires</em></p>
        )
    }


    return (
        <table className="table">
            <tr>
                <th/>
                <th className="text-center">Step 1</th>
                <th className="text-center">Step 2</th>
                <th className="text-center">Step 3</th>
            </tr>

            {questionnaires.map((questionnaire) => {
                const instanceId = questionnaire.instanceId;

                // TODO also check completeness as well as pdf
                return (
                    <tr>
                        <th style={{"verticalAlign": "middle"}}>{questionnaire.listName}</th>
                        <td className="text-center">
                            {

                                // TODO tick / cross by complete?
                                questionnaire.jobcomplete
                                    ? <a href={questionnaire.questionnairePdf} target="_blank">

                                        Print Questionnaire&nbsp;
                                        <Tick/>
                                    </a>
                                    : <Link
                                        to={ `${url}/${instanceId}/sections${questionnaire.lastSectionIndex === -1 ? '' : '/' + questionnaire.lastSectionIndex}`}
                                    >
                                        Fill in Questionnaire&nbsp;
                                        <span className="text-danger"><Cross/></span>
                                    </Link>
                            }

                        </td>
                        <td className="text-center">
                            {

                                // TODO tick / cross by complete?
                                questionnaire.termsPdf
                                    ? <a href={questionnaire.termsPdf} target="_blank">

                                        Print Terms of Engagement&nbsp;
                                        <Tick/>
                                    </a>
                                    : <Link
                                        to={`${url}/${instanceId}/terms`}
                                    >
                                        Print Terms of Engagement&nbsp;
                                        <span className="text-danger"><Cross/></span>
                                    </Link>
                            }
                        </td>
                        <td className="text-center">
                            <ChecklistLink questionnaire={questionnaire} url={url}/>
                            {/*<button*/}
                            {/*    type="submit"*/}
                            {/*    className="btn btn-success"*/}
                            {/*    onClick={() => dispatch(finaliseQuestionnaire(instanceId))}*/}
                            {/*>*/}
                            {/*    Generate Questionnaire PDF*/}
                            {/*</button>*/}
                        </td>
                    </tr>
                )
            })}
        </table>
    )
};
