import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/rootReducer";
import {MainLoader} from "../../components/LoadingIndicators";
import {acceptChecklist, fetchChecklist} from "./questionnaireChecklistSlice";
import {useHistory} from "react-router-dom";
import {QuestionnaireInstance} from "../../api/questionnaireAPI";
import {QuestionnaireHeading} from "../questionnaireDisplay/QuestionnaireHeading";
import {TextDisplay} from "../../components/TextDisplay";
import {deleteDocument, fetchDocuments} from "../files/filesSlice";
import {SidebarLoader} from "../../components/SidebarLoader";
import {QuestionnaireFiles} from "../questionnaireQuestionnaire/QuestionnaireFiles";
import {fetchQuestionnaire} from "../questionnaireQuestionnaire/questionnaireSlice";

interface ChecklistProps {
    questionnaire: QuestionnaireInstance;
}

export const Checklist = ({questionnaire}: ChecklistProps) => {
    const dispatch = useDispatch();




    // TODO use slice for checklist
    const {
        isLoading,
        error,
        checklist,
    } = useSelector((state: RootState) => state.questionnaireChecklist);


    const history = useHistory();

    const {instanceId} = questionnaire;

    useEffect(() => {
        // if there is a checklist PDF redirect home

        const jobAndTermsComplete = questionnaire.jobcomplete && questionnaire.termsofengagementcomplete;

        if (!jobAndTermsComplete ) {
            history.replace('/')
        }

        // load initial section state
        dispatch(fetchChecklist(instanceId, history));


    }, [dispatch, history, instanceId, questionnaire.checklistPdf]);

    if (isLoading) {
        return <MainLoader/>
    }

    if (error) {
        return <div>{error}</div>
    }

    return (

        <div className="container container-fluid">
            {/*<ChecklistFileSidebar questionnaire={questionnaire}/>*/}
            <QuestionnaireHeading questionnaire={questionnaire}/>


            <h3>Checklist</h3>

            <QuestionnaireFiles questionnaire={questionnaire}/>

            <div className="card my-3 d-print-none">
                <div className="card-body">

                    <p className="lead">This is a checklist of paperwork we require you to send in to us.</p>

                    <p>
                        Please print and post this to us with the items you have included checked off under the
                        column, <strong>Client
                        Check</strong>.
                    </p>

                    <p>
                        If you have any electronic files to attach, please do so now using the <strong>Add
                        Files</strong> button
                        above.
                    </p>

                    <p>
                        Use the <strong>Print Checklist</strong> button at the bottom of this page to open a new
                        tab
                        with a PDF version of
                        this checklist for printing.
                    </p>
                </div>
            </div>

            <table className="table bg-white">
                <tr>
                    <th>Item</th>
                    <th>Accountant Check</th>
                    <th>Client Check</th>
                </tr>

                {checklist.map((item) => {
                    return (
                        <tr>
                            <td><TextDisplay text={item}/></td>
                            <td/>
                            <td/>
                        </tr>
                    )
                })}
            </table>

            <div className="mb-3 mt-5">
                <button
                    className="float-right btn btn-lg btn-success"
                    onClick={() => dispatch(acceptChecklist(() => history.push('/questionnaires'), instanceId))}
                >
                    Print Checklist PDF
                </button>
            </div>
        </div>
    )
};
