import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../app/rootReducer";
import {Alert} from "react-bootstrap";
import {clearFlash, clearIfShown} from "../features/login/loginSlice";

const MainAlert = () => {
    const {
        flash
    } = useSelector((state: RootState) => state.login);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearIfShown())
    }, [
        dispatch
    ]);

    if (flash) {
        return (
            <Alert variant="info" onClose={() => dispatch(clearFlash())} dismissible>
                {/*<Alert.Heading>Oh snap! You got an error!</Alert.Heading>*/}
                <p>
                    {flash}
                </p>
            </Alert>
        );
    }
    return null;
};

export const HeadingContainer: React.FC = ({children}) => {
    return (
        <div>
            <div className="border-bottom border-dark mb-3">
                {children}
            </div>
            <MainAlert/>
        </div>
    )
};
