import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {
    getQuestionnaire,
    QuestionnaireInstance,
    submitTermsForm,
    transitionQuestionnaireToFinished
} from "../../api/questionnaireAPI";
import {fetchQuestionnaires} from "../questionnaireList/questionnaireListSlice";
import {History} from "history";

interface QuestionnaireState {
    questionnaire?: QuestionnaireInstance;
    error: string | null;
    isLoading: boolean;
    isFinalizing: boolean;
}

let questionnaireInitialState: QuestionnaireState = {
    isLoading: false,
    error: null,
    isFinalizing: false
};

function startLoading(state: QuestionnaireState) {
    state.isLoading = true;
}

function loadingFailed(state: QuestionnaireState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload;
}

const questionnaireListSlice = createSlice({
    name: 'questionnaire',
    initialState: questionnaireInitialState,
    reducers: {
        finalizingStart(state) {
            state.isFinalizing = true;
        },
        finalizingEnd(state) {
            state.isFinalizing = false;
        },
        getQuestionnaireStart: startLoading,
        getQuestionnaireSuccess(state, {payload}: PayloadAction<QuestionnaireInstance>) {
            state.questionnaire = payload;
            state.isLoading = false;
            state.error = null;
        },
        getQuestionnaireFailure: loadingFailed
    }
});


export const {
    finalizingStart,
    finalizingEnd,
    getQuestionnaireStart,
    getQuestionnaireSuccess,
    getQuestionnaireFailure,
} = questionnaireListSlice.actions;


export const fetchQuestionnaire = (
    id: number,
    successCallback?: (questionnaire: QuestionnaireInstance) => void

): AppThunk => async (dispatch) => {

    try {
        dispatch(getQuestionnaireStart());
        const questionnaires = await getQuestionnaire(id);
        dispatch(getQuestionnaireSuccess(questionnaires));

        successCallback && successCallback(questionnaires);
    } catch (err) {
        // redirect to login if 403

        // cancel is caused by another request cancelling the current request so ignore
        err.toString() === 'Cancel' || dispatch(getQuestionnaireFailure(err.toString()))
    }
};

export const finaliseQuestionnaire = (
    instanceId: number,
    successCallback: (questionnaire: QuestionnaireInstance) => void
): AppThunk => async (dispatch) => {
    try {
        dispatch(finalizingStart());
        await transitionQuestionnaireToFinished(instanceId);
        dispatch(fetchQuestionnaire(instanceId, successCallback));
    } catch (err) {

        // redirect to login if 403

        // cancel is caused by another request cancelling the current request so ignore
        // err.toString() === 'Cancel' || dispatch(getQuestionnaireFailure(err.toString()))
    } finally {
        dispatch(finalizingEnd());
    }
};

export const constSignTerms = (instanceId: number, e: any, electronic: boolean): AppThunk => async (dispatch) => {
    const data = await submitTermsForm(e, electronic);
    data && data.pdfLocation && window.open(data.pdfLocation, '_blank');

    dispatch(fetchQuestionnaires())

}


export default questionnaireListSlice.reducer;
