import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {getQuestionnaires, QuestionnaireInstance, Questionnaires} from "../../api/questionnaireAPI";
import {History} from "history";

interface QuestionnaireState {
    questionnaires: QuestionnaireInstance[];
    error: string | null;
    isLoading: boolean;
}

let questionnaireInitialState: QuestionnaireState = {
    questionnaires: [],
    // TODO should be false
    isLoading: false,
    error: null
};

function startLoading(state: QuestionnaireState) {
    state.isLoading = true;
}

function loadingFailed(state: QuestionnaireState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload;
}

const questionnaireListSlice = createSlice({
    name: 'questionnaires',
    initialState: questionnaireInitialState,
    reducers: {
        getQuestionnairesStart: startLoading,
        getQuestionnairesSuccess(state, {payload}: PayloadAction<Questionnaires>) {
            state.questionnaires = payload;
            state.isLoading = false;
            state.error = null;
        },
        getQuestionnairesFailure: loadingFailed
    }
});


export const {
    getQuestionnairesStart,
    getQuestionnairesSuccess,
    getQuestionnairesFailure,
} = questionnaireListSlice.actions;


// currently questionnaire will be fetched as a single block
export const fetchQuestionnaires = (
    all = true,
    //    params to be added for job id etc
): AppThunk => async (dispatch) => {

    try {
        dispatch(getQuestionnairesStart());
        const questionnaires = await getQuestionnaires(all);
        dispatch(getQuestionnairesSuccess(questionnaires));

    } catch (err) {

        // cancel is caused by another request cancelling the current request so ignore
        err.toString() === 'Cancel' || dispatch(getQuestionnairesFailure(err.toString()))

    }


};

export default questionnaireListSlice.reducer;
