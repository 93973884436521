import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/rootReducer";
import {match, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {QuestionnaireSelector} from "../questionnaireList/QuestionnaireList";
import {QuestionnaireTerms} from "../questionnaireDisplay/QuestionnaireTerms";
import {QDSID} from "../questionnaireDisplay/QuestionnaireDisplay";
import {Checklist} from "../questionnaireChecklist/Checklist";
import {getQuestionnaireSectionsStart} from "../questionnaireDisplay/questionnaireDisplaySlice";
import {fetchQuestionnaire} from "./questionnaireSlice";
import {MainLoader} from "../../components/LoadingIndicators";
import axios from "../../utils/axios";
import {QuestionnaireInstance} from "../../api/questionnaireAPI";

const QuestionnaireIdMatch = () => {
    let match: match<{ id: string }> = useRouteMatch();
    let history = useHistory();
    let dispatch = useDispatch();

    const {
        questionnaire,
        isLoading,
        error,
    } = useSelector((state: RootState) => state.questionnaire);

    const id = +match.params.id;

    useEffect(() => {
        dispatch(fetchQuestionnaire(id));
    }, [id, history, dispatch]);

    if (isLoading) {
        return <MainLoader/>
    } else if (error || !questionnaire) {
        return <div>{error}</div>
    } else {
        const checklistLink = `${match.url}/checklist`;
        return (
            <div>
                <Switch>
                    <Route path={`${match.path}/checklist`}>
                        <Checklist questionnaire={questionnaire}/>
                    </Route>

                    <Route path={`${match.path}/terms`}>
                        <QuestionnaireTerms checklistLink={checklistLink} questionnaire={questionnaire}/>
                    </Route>

                    <Route path={`${match.path}/sections/:selectedSection?`}>
                        <QDSID
                            checklistLink={checklistLink}
                            termsLink={`${match.url}/terms`}
                            generateSectionLink={(sectionIndex: number) => `${match.url}/sections/${sectionIndex}`}
                            onSectionChange={(sectionIndex) => {
                                dispatch(getQuestionnaireSectionsStart());
                                history.push(`${match.url}/sections/${sectionIndex}`)
                            }}
                            questionnaire={questionnaire}
                        />
                    </Route>
                </Switch>
            </div>
        )
    }
};

export const Questionnaire = () => {
    const {
        questionnaires,
    } = useSelector((state: RootState) => state.questionnaireList);

    let match: match = useRouteMatch();

    return (<Switch>
            <Route path={`${match.path}/:id`}>
                <QuestionnaireIdMatch/>
            </Route>

            <Route path={`${match.path}/`}>
                <QuestionnaireSelector questionnaires={questionnaires}/>
            </Route>
        </Switch>
    );
};

