import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    getPasswordRule,
    LoginDetails,
    optionsCheckLoggedIn,
    PasswordRule,
    postLogout,
    SelectConfig
} from "../../api/loginApi";
import {AppThunk} from "../../app/store";
import {History} from 'history';

export interface Select {
    login: LoginDetails;
    selectConfig: SelectConfig
}

interface LoginState {
    select?: Select;

    // TODO for dev
    flash?: string
    shown: boolean;
    isLoading: boolean
    error: string | null
    passwordRule?: PasswordRule
}

const loginInitialState: LoginState = {
    isLoading: false,
    error: null,
    shown: false
};


function startLoading(state: LoginState) {
    state.isLoading = true
}

function loadingFailed(state: LoginState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload
}

//TODO split password rule into own slice?

const loginSlice = createSlice({
    name: 'login',
    initialState: loginInitialState,
    reducers: {
        setSelect(state, {payload}: PayloadAction<Select>) {
            state.select = payload;
        },
        setFlash(state, {payload}: PayloadAction<string>) {
            state.flash = payload;
            state.shown = false;
        },
        clearFlash(state) {
            state.flash = undefined;
        },
        clearIfShown(state) {
            if (state.shown) {
                state.flash = undefined;
                state.shown = false;
            } else {
                state.shown = true;
            }
        },
        getPasswordRuleStart: startLoading,
        getPasswordRuleSuccess(state, {payload}: PayloadAction<PasswordRule>) {
            state.passwordRule = payload;
            state.isLoading = false;
            state.error = null;
        },
        getPasswordRuleFailure: loadingFailed
    }
});

export const {
    setSelect,
    setFlash,
    clearFlash,
    getPasswordRuleStart,
    getPasswordRuleSuccess,
    getPasswordRuleFailure,
    clearIfShown
} = loginSlice.actions;

export const checkLoggedIn = (history: History): AppThunk => async (dispatch) => {
    try {
        await optionsCheckLoggedIn();
    } catch (err) {
        console.error(err);

        if (err.response && err.response.status === 403) {
            history.push('/login')
        }
    }
}

export const fetchPasswordRule = (history: History): AppThunk => async (dispatch) => {
    try {
        dispatch(getPasswordRuleStart());
        const passwordRule = await getPasswordRule();
        dispatch(getPasswordRuleSuccess(passwordRule));
    } catch (err) {
        // logout if 401
        if (err.response && err.response.status === 403) {
            history.push('/login')
        } else {
            dispatch(getPasswordRuleFailure(err.toString()))
        }
    }
};

export const logout = (history: History): AppThunk => async (dispatch) => {
    await postLogout();
    // redirect to login
    history.push('/login')
}

export default loginSlice.reducer;
