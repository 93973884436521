import {Field, useField} from "react-final-form";
import {motion} from "framer-motion";
import {TextDisplay} from "../../components/TextDisplay";
import React, {useEffect, useRef, useState} from "react";
import {Question, Section} from "../../api/questionnaireAPI";
import {QuestionQueryButton} from "../questionnaireQuestionQuery/QuestionQueryButton";

interface QuestionFieldProps {
    noQuery?: boolean;
    inputOnly?: boolean;
    question: Question;
    instanceId: number;
    namePrefix?: string;
    isDerived: boolean;
    numeric: boolean;
}

export const generateFieldName = (question: Question, namePrefix: string | undefined) => {
    let name = question.name;
    if (namePrefix) {
        name = `${namePrefix}.${question.name}`;
    }
    return name;
}

export const QuestionField = (props: QuestionFieldProps) => {
    const {question, namePrefix, inputOnly, noQuery} = props;
    // const selector = formValueSelector('sectionForm');
    // const state = useSelector((state: RootState) => state);
    let name = generateFieldName(question, namePrefix);

    const fieldHook = useField(name);

    const value1 = fieldHook.input.value;
    const answered = value1 !== undefined && value1 !== '';

    // const curAnswer = selector(state, name);
    // const curAnswer = selector(state, name);

    let inputId = `${name}-input`;
    const helpTextId = `${name}-input`;

    let helpTextDisplay = null;

    if (question.helpText) {
        helpTextDisplay = <HelpText id={helpTextId} text={question.helpText}/>
    }

    let field: JSX.Element | null =
        <Field
            step={props.numeric ? "any" : null}
            disabled={props.isDerived}
            aria-describedby={helpTextDisplay ? helpTextId : null}
            type={props.numeric ? "number" : "text"}
            component="input"
            // TODO - this shows invalid on initial render as we don't prevent incomplete submission
            className={answered ? "form-control" : "form-control is-invalid"}
            name={name}
            // required
        />;

    let type = question.type;
    if (type.name === 'LABEL') {
        field = null;
    } else if (type.name === 'SELECT') {
        const questionOptions = type.questionOptions;
        const length = questionOptions.length;

        if (length > 5) {
            field = <Field
                className="custom-select"
                id={inputId}
                name={name}
                component="select"
                aria-describedby={helpTextDisplay ? helpTextId : null}
            >
                {questionOptions.map((questionOption) => {
                    return (
                        <option value={questionOption.name}>
                            {questionOption.value}
                        </option>
                    )
                })}
            </Field>;
        } else if (length > 0) {
            field =
                <div>
                    <fieldset id={name + '-group'}>
                        <div className="d-flex align-content-start flex-wrap mb-n2">
                            {
                                questionOptions.map((questionOption, index) => {
                                    let value = questionOption.name;

                                    return (
                                        <div className="btn-group btn-group-toggle mr-2 mb-2">
                                            <Field
                                                name={`${name}`}
                                                value={`${value}`}
                                                component="input"
                                                type="radio"
                                                autoComplete="off"
                                            >
                                                {props => {
                                                    return <label
                                                        className={"btn btn-outline-primary" + (props.input.checked ? ' active' : '')}>
                                                        <input  {...props.input}  />
                                                        {questionOption.value}
                                                    </label>
                                                }}
                                            </Field>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </fieldset>
                </div>
        } else {
            return null;
        }
    }

    let content = field;


    if (inputOnly) {
        return (
            content
        )
    }

    return (
        <div className="form-group question-input">
            <label htmlFor={inputId} style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between"
            }}>
                    <span dangerouslySetInnerHTML={{__html: question.questionText}}/>

                {
                    noQuery ? null :
                    <div className="mb-auto ml-2">
                        <QuestionQueryButton question={question} questionnaireId={props.instanceId} query={question.query}/>
                    </div>
                }
            </label>

            {content}
            {helpTextDisplay}
        </div>
    );
};

interface HTProps {
    text: string,
    id: string
}


const HelpText: React.FC<HTProps> = ({id, text}) => {
    const [height, setHeight] = useState(0);
    const [collapsed, setCollapsed] = useState(true);
    const ref = useRef(null);

    useEffect(() => {
        if (ref && ref.current) {
            // @ts-ignore
            setHeight(ref.current.clientHeight)
        }
    }, []);

    window.addEventListener('resize', () => {
        if (ref && ref.current) {
            // @ts-ignore
            setHeight(ref.current.clientHeight);
        }
    });

    // TODO don't do by style animate
    const maxHeight = 40;
    let isBigger = height > maxHeight;

    let collapseButton = null;

    if (isBigger) {
        collapseButton = <button type="button" onClick={() => setCollapsed(!collapsed)}
                                 className="btn btn-sm btn-link p-0">{collapsed ? "+show more" : "-show less"}</button>
    }

    let style = {maxHeight: maxHeight, overflow: "hidden"};
    return (
        <small id={id} className="form-text text-muted">
            <div style={collapsed ? style : undefined}>
                <div ref={ref}>
                    <TextDisplay text={`${text}`}/>
                </div>
            </div>
            <div>
                {collapseButton}
            </div>
        </small>
    )
}

interface InjectedFieldAnimationProps {
    question: Question
}

export const withFieldAnimation = <P extends object>(Component: React.ComponentType<P>) => (
    class FieldAnimation extends React.Component<P & InjectedFieldAnimationProps> {
        render() {
            let props = this.props;

            return (
                <motion.div
                    animate
                    key={props.question.name}
                >
                    <Component {...props as P} />
                </motion.div>
            );
        }
    }
);

