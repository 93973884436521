import React, {useEffect} from "react";
import {QuestionnaireInstance} from "../../api/questionnaireAPI";
import "../../scss/dropzone.scss";
import {Button, Modal} from "react-bootstrap";
import {deleteDocument, fetchDocuments} from "./filesSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/rootReducer";
import {SidebarLoader} from "../../components/SidebarLoader";
import {FileDisplay, FileUploaderModal} from "./FileUploader";

interface FMProps {
    show: boolean,
    onHide: () => void,
    questionnaire: QuestionnaireInstance
}

export const FilesModal = ({questionnaire, onHide, show}: FMProps) => {
    const dispatch = useDispatch();
    const {
        isLoading,
        error,
        documents,
        progress,
        isUploading
    } = useSelector((state: RootState) => state.documents);

    const {instanceId} = questionnaire;

    useEffect(() => {
        dispatch(fetchDocuments(instanceId));
    }, [dispatch, instanceId]);


    let uploadIndicator = null;
    if (isUploading && progress !== undefined) {
        uploadIndicator = <div className="progress">
            <div className="progress-bar" style={{
                width: `${progress}%`
            }} role="progressbar" aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}/>
        </div>
    }

    let content = null;
    if (isLoading) {
        content = <SidebarLoader/>
    } else {
        content =
            <React.Fragment>
                {/*<h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">*/}
                {/*    <span>{finalization ? 'Finalization' : 'Questionnaire Sections'}</span>*/}
                {/*</h6>*/}
                {/**/}
                {/*TODO move to files*/}

                <ul className="list-group list-group-flush">
                    {documents.map((document) =>
                        <FileDisplay {...document} onDelete={() => {
                            dispatch(deleteDocument(questionnaire.instanceId, document))
                        }}/>
                            //TODO show delete status
                    )}
                </ul>

            </React.Fragment>
    }

    return (
        <Modal show={show} onHide={onHide}>
            {/** @ts-ignore **/}
            <Modal.Header>
                <Modal.Title>
                    <span>Files</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content}

                {uploadIndicator}

                <FileUploaderModal questionnaire={questionnaire} documents={documents}/>
            </Modal.Body>

            <Modal.Footer>
                <Button type="button" variant="secondary" onClick={onHide}> Close </Button>
            </Modal.Footer>
        </Modal>
    )
};
