import React, {useState} from "react";
import {FilesModal} from "../files/FilesModal";
import {QuestionnaireInstance} from "../../api/questionnaireAPI";
import {QuestionQueryButton} from "../questionnaireQuestionQuery/QuestionQueryButton";

interface QFProps {
    questionnaire: QuestionnaireInstance
}

export const QuestionnaireFiles = ({questionnaire}: QFProps) => {
    const [showFiles, setShowFiles] = useState(false);
    let filesBar = null;
    // TODO better layout of file button
    if (questionnaire.filesAllowed) {
        filesBar = <div>
            <div className="d-flex mb-3">
                <div className="ml-auto">
                    <button className="btn btn-primary mr-1" onClick={() => setShowFiles(true)}>Add/Remove Files</button>
                    {questionnaire.jobcomplete ? null : <QuestionQueryButton questionnaireId={questionnaire.instanceId} query={questionnaire.clientnotes}/>}
                </div>
            </div>
            <FilesModal show={showFiles} onHide={() => setShowFiles(false)} questionnaire={questionnaire}/>
        </div>
    }

    return (
        filesBar
    )
};
