import React from "react";
import {QuestionnaireInstance} from "../../api/questionnaireAPI";
import {HeadingContainer} from "../../components/HeadingContainer";

interface QHProps {
    questionnaire: QuestionnaireInstance
}

export const QuestionnaireHeading = ({questionnaire}: QHProps) => {
    let organisationName = null;

    if (questionnaire.organisationName) {
        organisationName = <h2>{questionnaire.organisationName}</h2>
    }

    return (
        <HeadingContainer>
            {organisationName}
            <h3>{questionnaire.name}</h3>
        </HeadingContainer>
    )
};
