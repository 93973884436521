import React, {useState} from "react";
import {QuestionnaireDocument, QuestionnaireInstance} from "../../api/questionnaireAPI";
import "../../scss/dropzone.scss";
import {FileRejection, useDropzone} from "react-dropzone";
import {Alert, Button} from "react-bootstrap";
import {convertToReadableFileSize} from "../../utils/readableFileSize";
import {useDispatch} from "react-redux";
import {Form} from "react-final-form";
import {postDocuments} from "./filesSlice";

interface FUProps {
    documents: QuestionnaireDocument[];
}

interface FUMProps extends FUProps {
    questionnaire: QuestionnaireInstance
}

type FileErrors = { sizeErrorFiles: File[], alreadyAddedFiles: File[], alreadyUploadedFiles: File[] };
//TODO display upload error
export const FileUploaderModal = ({questionnaire, documents}: FUMProps) => {
    const [files, setFiles] = useState<File[]>([]);
    const [errorFiles, setErrorFiles] = useState<FileErrors>({
        alreadyAddedFiles: [],
        alreadyUploadedFiles: [],
        sizeErrorFiles: []
    });

    const dispatch = useDispatch();

    const {alreadyAddedFiles, alreadyUploadedFiles, sizeErrorFiles} = errorFiles;

    const clearErrors = () => {
        setErrorFiles({
            alreadyAddedFiles: [],
            alreadyUploadedFiles: [],
            sizeErrorFiles: []
        })
    }


    const onRemoveFile = (index: number) => {
        setFiles([
            ...files.slice(0, index),
            ...files.slice(index + 1)
        ]);
        clearErrors();
    };

    const clearAll = () => {
        clearErrors();
        setFiles([]);
    }

    const maxSize = 40;
    const maxSizeBytes = mbToBytes(maxSize);
    const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        console.log(rejectedFiles)
        // Do something with the files

        // size errors
        let tooLarge: File[] = rejectedFiles.filter((rejectedFile) => (
            maxSize && rejectedFile.file.size > maxSizeBytes
        )).map((fileRejection) => fileRejection.file);

        // 'Files:', ...toFilesString(tooLarge), `are larger than ${maxSize}mb`);

        // if (this.props.singleFile && accepted.length > 0) {
        //     this.setState({
        //         files: [accepted[0]]
        //     });
        // } else {
        // already uploaded / to be uploaded errors
        // get the currently uploaded files and files to be uploaded
        const uploadedFiles: { [key: string]: boolean } = {}, toUploadFiles: { [key: string]: boolean } = {};

        documents.forEach(({name}) => uploadedFiles[name] = true);
        files.forEach(({name}) => toUploadFiles[name] = true);

        let alreadyUploaded: File[] = [];
        let alreadyAdded: File[] = [];
        // filter out and add errors for already uploaded / to be uploaded files
        const acceptedFilesToAdd = acceptedFiles.filter((file) => {
            const name = file.name;
            if (uploadedFiles[name]) {
                alreadyUploaded.push(file);
                return false;
            } else if (toUploadFiles[name]) {
                alreadyAdded.push(file);
                return false;
            }
            return true;
        });

        // errors.push(`Files with name`, ...toFilesString(alreadyUploaded), 'already uploaded');
        // errors.push(`FileDisplay with name`, ...toFilesString(alreadyAdded), `already added`);
        setErrorFiles({
            sizeErrorFiles: tooLarge,
            alreadyUploadedFiles: alreadyUploaded,
            alreadyAddedFiles: alreadyAdded
        });

        dispatch(postDocuments(questionnaire.instanceId, [...files, ...acceptedFilesToAdd], () => {
        }))
    }

    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
        maxSize: maxSizeBytes,
        onDrop,
        noClick: true
    })


    let alerts = [
        {
            files: alreadyAddedFiles, suffix: "already added", onClose: () => setErrorFiles({
                ...errorFiles,
                alreadyAddedFiles: []
            })
        },
        {
            files: alreadyUploadedFiles, suffix: "already uploaded", onClose: () => setErrorFiles({
                ...errorFiles,
                alreadyUploadedFiles: []
            })
        },
        {
            files: sizeErrorFiles, suffix: `larger than ${maxSize}mb`, onClose: () => setErrorFiles({
                ...errorFiles,
                sizeErrorFiles: []
            })
        },
    ]
        .filter(({files}) => files.length !== 0)
        .map(({files, suffix, onClose}) => {
            return (
                <p>
                    {files.map(({name}) => <div><em>{name}</em></div>)}
                    <div><strong>{suffix}</strong></div>
                </p>
            )
        });

    let alert = null;
    if (alerts.length !== 0) {
        alert = <Alert variant="danger" onClose={clearErrors} dismissible>
            <div className="mb-n3">
                {alerts.map((alert) => alert)}
            </div>
        </Alert>
    }

    return (
        <div>
            <div>
                {alert}
                {/*<div {...getRootProps({*/}
                {/*    className: isDragActive ? 'ao-dropzone ao-dropzone-active' : 'ao-dropzone',*/}
                {/*})}>*/}
                    <input {...getInputProps()} />

                    {/*<section>*/}
                    {/*    <div style={{textAlign: 'center'}}>*/}
                    {/*        <input {...getInputProps()} />*/}

                    {/*        <p><b>Drop files here to upload</b></p>*/}
                    {/*        <div style={{marginTop: 15}}>*/}
                                <button type="button" className="btn btn-primary" onClick={() => open()}>Upload Files                                </button>
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                </div>
            {/*</div>*/}
            {/*<Files files={files} onRemove={onRemoveFile}/>*/}

            {/*<Form*/}
            {/*    onSubmit={(files: File[]) => dispatch(postDocuments(questionnaire.instanceId, files, () => {*/}
            {/*    }))}*/}
            {/*    initialValues={files}*/}
            {/*    render={({handleSubmit, submitting, pristine, invalid, values, errors}) => {*/}
            {/*        return (*/}
            {/*            <form onSubmit={handleSubmit}>*/}
            {/*                <button disabled={submitting} className="btn btn-primary" type="submit">Upload</button>*/}
            {/*            </form>*/}
            {/*        );*/}
            {/*    }}*/}
            {/*/>*/}
        </div>
    )
};

const mbToBytes = (mb: number) => mb * (2 ** 20);


interface FilesProps {
    files: File[],
    onRemove: ((index: number) => void)
}

const Files = ({files, onRemove}: FilesProps) => {
    let filesContent: JSX.Element | JSX.Element[];

    if (files.length > 0) {
        filesContent =
            <ul className="list-group list-group-flush">
                {/*{files.map((file, index) => <FileDisplay key={file.name} file={file} onDelete={() => onRemove(index)}/>)}*/}
            </ul>

    } else {
        filesContent = <div className="text-center">
            <em>No files added</em>
        </div>
    }
    return (
        <div style={{marginTop: 15}}>
            <h3>Files</h3>
            <div style={{marginTop: 10}}>
                {filesContent}
            </div>
        </div>
    );
};

interface FileProps {
    downloadLink: string;
    name: string;
    size: number;
    onDelete?: () => void;
}

// displays the file with name / download link and delete button
export const FileDisplay = ({downloadLink, name, size, onDelete}: FileProps) => {
    // if the download link is defined render a link
    const displayContent =
        downloadLink
        ? <a href={downloadLink}>{name}</a>
        : <span>{name}</span>;

    let deleteButton = null;
    if (onDelete) {
        deleteButton =
            <button
                className="btn btn-outline-danger"
                type="button"
                // style={{verticalAlign: "middle"}}
                aria-label="delete"
                aria-disabled="false"
                onClick={onDelete}
            >
                {/*Remove*/}
                <i className="fa fa-remove"/>
            </button>;
    }

    const {size: unitSize, unit} = convertToReadableFileSize(size);

    return (
        <li className="list-group-item px-0">
            <div className="d-flex justify-content-between">
                <div>
                    <h6>
                        {displayContent}
                    </h6>
                    <p className="mb-0">
                        <small>{unitSize.toFixed(2)} {unit}</small>
                    </p>
                </div>
                <div>
                    {deleteButton}
                </div>
            </div>
        </li>
    );
};
